import {RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {DashboardComponent} from './dashboard/dashboard.component';
import {AppMainComponent} from './app.main.component';
import {LoginComponent} from './components/login/login.component';
import {SignupComponent} from './components/login copy/singup.component';

import {NotfoundComponent} from './components/notfound/notfound.component';
import {AccessComponent} from './components/access/access.component';
import {AuthGuard} from './guards/auth.guard';
import {ConnectionListComponent} from "./components/connection/connection-list/connection-list.component";
import {UserListComponent} from "./components/user-list/user-list.component";
import {
    ConnectionAnalyticsComponent
} from "./components/connection/connection-analytics/connection-analytics.component";
import {AdminGuard} from "./guards/admin.guard";
import {MeetingComponent} from "./meeting/meeting.component";
import {IntegrationComponent} from "./components/integration/integration.component";
import { OTPVerificationComponent } from './components/otp-varification/otp-verification.component';
import { LicenceComponent } from './components/licence/licence.component';
import { QuerentComponent } from './querent/querent.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { QuerentV2Component } from './querent-v2/querent-v2.component';
import { AnalyticsComponent } from './analytics/analytics.component';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';

@NgModule({
    imports: [
        RouterModule.forRoot(
            [
                {
                    path: '',
                    component: AppMainComponent,
                    canActivate: [AuthGuard],
                    children: [
                        {path: '', component: DashboardComponent},
                        {path: 'meetings', component: ConnectionListComponent},
                        {path: 'meetings-details', component: MeetingComponent},
                        {path: 'connections/:id', component: ConnectionAnalyticsComponent},
                        {path: 'users', component: UserListComponent,canActivate:[AdminGuard]},
                        {path: 'integration', component: IntegrationComponent},
                        {path: 'licence', component: LicenceComponent},
                        {path: 'chat', component: QuerentV2Component},
                        {path: 'chat-v2', component: QuerentV2Component},
                        {path: 'analytics', component: AnalyticsComponent},
                        {path: 'coming-soon', component: ComingSoonComponent},


                    ],
                },
                {path: 'login', component: LoginComponent},

                {path: 'signup', component: SignupComponent},
                {path: 'forgot-password', component: ForgotPasswordComponent},

                {path: 'notfound', component: NotfoundComponent},
                {path: 'access', component: AccessComponent},
                {path: 'otp', component: OTPVerificationComponent},

                {path: '**', redirectTo: 'notfound'},
            ],
            {scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled'}
        ),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
