import {Injectable} from '@angular/core';
import {MessageService} from "primeng/api";
import jsPDF from 'jspdf';
import moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class UtilsService {

    constructor(private messageService: MessageService) {
    }

    textToAvatarText(text) {
        let avatarText = ''
        const s = text.split(' ');

        s.forEach(element => {

            /* charAt(0) will pick only the first character
            from the string and append to buffer */
            avatarText += element[0];
        });

        if (avatarText.length > 2) {
            avatarText = avatarText.substring(0, 2);
        }

        return avatarText;
    }

    copyToClipboard(val: string) {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.messageService.add({severity: 'success', summary: 'Copied to clipboard',})
    }

    downloadPDF(data, fileName) {
        let blob = new Blob([data], {
            type: 'application/pdf'
            // type: 'application/octet-stream' // for excel
        });
        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement("a");

        link.href = blobUrl;
        link.download = fileName + ".pdf";
        document.body.appendChild(link);

        link.dispatchEvent(
          new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window
          })
        );

        window.open(blobUrl, '_blank');
        document.body.removeChild(link);
        setTimeout(() => URL.revokeObjectURL(link.href), 7000);
    }

    downloadAsPDF(pdfContent, fileName) {
        // const width = pdfContent.nativeElement.clientWidth;
        // const height = pdfContent.nativeElement.clientHeight + 50000;
        // console.log(pdfContent.nativeElement.lastChild.lastChild.lastChild);

        // let orientation = '';
        // if (width > height) {
        //     orientation = 'landscape';
        // } else {
        //     orientation = 'portrait';
        // }
    
            // I am not good with ts, ideally the values should come from object. Issue with setting a type
            // let jsPdfOptions = {
            //     orientation: 'portrait',
            //     unit: 'pt',
            //     format: 'a4'
            // };
            const pdf = new jsPDF('portrait', 'pt', 'a4');
            // pdf.setFontSize(24);
            // pdf.setTextColor('#131523');
            pdf.text(fileName, 25, 50);
            // pdf.setFontSize(14);
            // pdf.setTextColor('#131523');
            pdf.text(moment().format('ll'), 25, 70);
            // pdf.html(pdfContent);
            pdf.html(pdfContent, {
                callback: function (doc) {
                    pdf.save(`${fileName}` + '.pdf');
                },
                x: 10,
                y: 10
             })
        
    }

        b64toBlob = (base64, type = 'application/octet-stream') =>
        fetch(`data:${type};base64,${base64}`).then(res => res.blob())
}
