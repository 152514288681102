<div class="grid">
    <div class="col-12">
        <div class="card p-0">
            <p-toolbar>
                <ng-template pTemplate="left">
                    <div>
                        <span class="p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input type="text" pInputText placeholder="Search" (input)="dt.filterGlobal($event?.target?.value, 'contains')">
                        </span>
                    </div>
                </ng-template>
            </p-toolbar>
            <p-table #dt [value]="users" responsiveLayout="scroll" [lazy]="true" (onLazyLoad)="getUsers($event)"
                     [paginator]="true" [rows]="10" [totalRecords]="totalDocs">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Connections</th>
                        <th pSortableColumn="createdAt">Created At
                            <p-sortIcon field="createdAt"></p-sortIcon>
                        </th>
                        <th>Last Login</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-user let-columns="columns">
                    <tr>
                        <td>
                            <p-avatar [label]="utilsService.textToAvatarText(user.name)" size="large"></p-avatar>
                            {{user.name}}
                        </td>
                        <td>{{user.email}}</td>
                        <td>{{user.connections}}</td>
                        <td>{{user.createdAt | date: 'DD/MM/YYYY'}}<br>{{user.createdAt | date: 'shortTime'}}</td>
                        <td>{{user.lastLogin | date: 'DD/MM/YYYY'}}<br>{{user.lastLogin | date: 'shortTime'}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>
