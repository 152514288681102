import { Component, OnInit } from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { LoginService } from './service/login.service';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
})
export class AppTopBarComponent implements OnInit {
    expireMessage = ''
    // warnMessages = [
    //     { severity: 'error', detail: 'Quick heads up: Your license for meetarise has expired in 14 days.To renew please reach out to us at <a>hello@meetarise.com</a>.' },
    // ];
    name: string;
    initials: string;
    visibleDialog = false
    backgroundColor: string;
    user: any;
    licence = {
        totalHours: 0,
        usedHours: 0
    }
    isPlanExpireInFewDays = true
    constructor(
        public appMain: AppMainComponent,
        private loginService: LoginService,
    ) { }

    ngOnInit(): void {
        if (this.loginService.getToken()) {
            this.user = this.loginService.getUser();
            this.getLicence()
            if (!this.user?.profileImg) {
                this.name = this.user.firstName
            }
            this.generateInitials();
            this.generateBackgroundColor();
        }
    }

    logout() {
        this.loginService.googleSignOut();
    }
    async getLicence() {
        let licence = await this.loginService.getLicence()
        if (!licence) {
            licence = {
                totalHours: 0,
                usedHours: 0,
                yearlyData: []
            }
        }
        this.loginService.setLicence(licence)
        const subscriptionsDetails = licence.subscriptionsDetails.find(plan => plan.isActive)
        const { isPlanExpireInFewDays, differenceInDays }
            = this.dateDifferenceGreaterThan14Days(subscriptionsDetails.purchaseDate, subscriptionsDetails.subscription.expireDate, subscriptionsDetails.subscription.validDays)
        if (!isPlanExpireInFewDays) {
            this.isPlanExpireInFewDays = isPlanExpireInFewDays
            this.expireMessage  = `Your license for meetarise has expired in ${differenceInDays} days. Upgrade your subscription to experience the full potential of our services! `
            // this.warnMessages[0] = { severity: 'error', detail: `Quick heads up: Your license for meetarise has expired in ${differenceInDays} days.To renew please reach out to us at hello@meetarise.com.` }
        }
        if(differenceInDays === 0){
            this.visibleDialog = true
        }
        this.licence = licence?.yearlyData.find(e => e.year == new Date().getFullYear())
    }
    private generateInitials() {
        try {
            const nameParts = this.name.split(' ');
            this.initials = nameParts.map(part => part[0]).join('').toUpperCase();
        } catch (error) {
            console.error("generateInitials", error)
        }

    }

    private generateBackgroundColor() {
        try {
            // You can implement a simple algorithm to generate a color based on the user's name
            // This example generates a color based on the ASCII values of the characters in the name
            const colorValue = this.name.split('').reduce((sum, char) => sum + char.charCodeAt(0), 0);
            this.backgroundColor = `rgb(${colorValue % 256}, ${(colorValue + 50) % 256}, ${(colorValue + 100) % 256})`;
        } catch (error) {
            console.error("generateBackgroundColor", error)
        }

    }
    dateDifferenceGreaterThan14Days(date1, date2, validDays) {
        // Convert date strings to Date objects
        const date1Obj: any = new Date(date1);
        const date2Obj: any = new Date(date2);
        // console.log(date1Obj, date1)

        // console.log(date2Obj, date2)
        // Calculate the difference in milliseconds
        const differenceInMs = Math.abs(date1Obj - date2Obj);
        // console.log(differenceInMs)
        // Convert milliseconds to days
        const differenceInDays = differenceInMs / (1000 * 60 * 60 * 24);
        // console.log(differenceInDays)

        // Check if the difference is greater than 14 days
        return {
            isPlanExpireInFewDays: differenceInDays >= validDays,
            differenceInDays
        };
    }
    openLink(link){
        const a = document.createElement('a');
      a.href =link
      document.body.appendChild(a);
      a.click()
    }   
}
