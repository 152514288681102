<!-- <div class="main-container"> -->
    <div class="grid quarant-cover">
        <div class="col-12 md:col-7 image-div">
            <div style="height: 100%; display: flex; flex-direction: column;">
                <div class="d-flex">
                    <h5>Files & Folders</h5>
    
                </div>
    
                <p-tabView style="height: 580px; overflow-y: auto; background: white;" (onChange)="switchHeaders($event);">
    
                    <div>
                        <div class="file-path d-flex" *ngIf="selectedFolderId">
                            <h6 class="cursor-pointer" (click)="selectedFolderId = null">Folder
                                <i style="font-size: 10px;" class="pi pi-chevron-right"></i>
                                <span class="folder-title">{{selectedFolderId?.name}}</span>
                            </h6>
                        </div>
                        <div class="upload-btns">
                            <button *ngIf="currentSelection =='FILE' && !selectedFolderId" (click)="showUploadDocumentModal = true"
                                class="blue-btn cursor-pointer upload-btn">
                                <img src="/assets/images/file-upload-btn.svg" height="18" width="18" alt="Icon" />
                                Upload Document
                            </button>
                            <button *ngIf="currentSelection =='FOLDER' && !selectedFolderId"
                                (click)="showFolderCreateDialog = true" class="blue-btn cursor-pointer upload-btn">
                                <img src="/assets/images/file-upload-btn.svg" height="18" width="18" alt="Icon" />
                                Create Folder
                            </button>
                        </div>
    
                    </div>
                    <p-tabPanel header="Documents" style="height:500px; overflow-x: scroll;"
                        (click)="currentSelection='FILE';selectedFolderId=false">
                        <app-files [allFiles]="allFiles" [isFile]=true></app-files>
                    </p-tabPanel>
                    <p-tabPanel header="Folders" (click)="currentSelection='FOLDER'">
                        <app-files *ngIf="!selectedFolderId" [allFiles]="folders" [isFile]=false
                            (fileFolderSelect)="fileFolderSelection($event)"></app-files>
                        <app-files *ngIf="selectedFolderId" [allFiles]="folderFiles" [isFile]=true
                            (fileFolderSelect)="fileFolderSelection($event)"></app-files>
                    </p-tabPanel>
                </p-tabView>
    
            </div>
        </div>
        <div class="col-12 md:col-5 chat-box">
            <div class=" d-flex conversation-label">
                <div class="chat-selection">
                    Files <p-inputSwitch class="switch"  [(ngModel)]="chatMode"></p-inputSwitch> <span class="switch">Folder</span>
    
                </div>
                <div class="file-select">
    
                    <p-dropdown [options]="allFiles" (ngModelChange)="selectFileToChatEvent($event)" *ngIf="!chatMode"
                        [(ngModel)]="selectedFileForChat" optionLabel="fileName" placeholder="Select file to start chat"
                        [style]="{ minWidth: '130px', width: '50%' }"></p-dropdown>
                        <p-dropdown [options]="folders" (ngModelChange)="selectFileToChatEvent($event)" *ngIf="chatMode"
                        [(ngModel)]="selectedFileForChat" optionLabel="name" placeholder="Select folder to start chat"
                        [style]="{ minWidth: '130px', width: '50%' }"></p-dropdown>
    
                </div>
    
            </div>
            <div class="conversation" id="scrollBottom">
                <ng-container *ngFor="let message of messages; index as index">
                    <div class="bot-message" *ngIf="message.from === 'bot'">
                        <p class="label">Assistant</p>
                        <p class="text">
                            {{ message.message }}
                        </p>
                    </div>
                    <div class="user-message" *ngIf="message.from === 'user'">
                        <p class="text">
                            {{ message.message }}
                        </p>
                    </div>
                </ng-container>
                <div class="bot-typing" *ngIf="isTyping">
                    <div id="wait">
                        <div class="loader--container">
                            <div class="spinner">
                                <div class="bounce1"></div>
                                <div class="bounce2"></div>
                                <div class="bounce3"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="message-input flex align-self-end">
                <input type="text" pInputText placeholder="Type a message." [(ngModel)]="message" (keyup.enter)="sendMessage()" />
                <div class="action-div" (click)="sendMessage()">
                    <img src="/assets/images/arrow-right.svg" height="12" width="6" alt="Icon" />
                </div>
            </div>
        </div>
    </div>
    
    <p-confirmDialog [style]="{ width: '50vw' }" [baseZIndex]="10000"></p-confirmDialog>
    
    <p-sidebar [(visible)]="visibleSidebar2" position="right" [baseZIndex]="10000">
        <div class="slider-main-container">
            <div class="voice-main-container">
                <div class="title mb-1">Voice</div>
                <p-dropdown [options]="speechData.voices" [(ngModel)]="voice" placeholder="Select a Voice"
                    optionLabel="name"></p-dropdown>
            </div>
            <div class="chat-font-size">
                <div class="title">Chat font size</div>
                <div class="container">
                    <div class="size-decrease" (click)="decreaseFont()">
                        <i class="pi pi-minus icon-action"></i>
                    </div>
                    <div class="font-display">
                        <span>{{ fontSize + "px" }}</span>
                    </div>
                    <div class="size-increase" (click)="increaseFont()">
                        <i class="pi pi-plus icon-action"></i>
                    </div>
                </div>
            </div>
        </div>
    </p-sidebar>
    
    <p-dialog header="Upload files" [modal]="true" [(visible)]="showUploadDocumentModal" [style]="{ width: '492px' }">
        <div id="file-upload-form" class="uploader" [appDragDrop]="dragDropEnabled" (dropped)="handleFileDrop($event)">
            <input type="file" [accept]="fileType" id="fileInput" [multiple]="multiple" (change)="addFiles(fileInput.files)"
                #fileInput class="visually-hidden" />
    
            <label for="file-upload" id="file-drag" class="file-upload-label">
                <div id="start">
                    <img src="/assets/images/file-upload.svg" height="60" width="68" alt="Icon" />
                    <p class="font-bold heading">
                        Drag & drop files or
                        <span (click)="selectFileUpload()" class="underline cursor-pointer">Browse</span>
                    </p>
                    <p class="sub-text">Supported formats: Pdf, XLS, ppt, docs</p>
                </div>
                <div id="response" *ngIf="fileUploadProgress">
                    <div id="messages"></div>
                    <p-progressBar [value]="fileUploadProgress"></p-progressBar>
                </div>
            </label>
            <div class="">
                {{ this.file?.name }}
            </div>
        </div>
        <div class="upload-actions">
            <button label="Cancel" severity="secondary" (click)="showUploadDocumentModal = false"
                class="cancel-btn cursor-pointer">
                Cancel
            </button>
            <button label="Save"
                (click)="showUploadDocumentModal = false; fileUploaed = true;getAllFiles(selectedFolderId?._id)"
                class="blue-btn ml-4 cursor-pointer">
                Upload
            </button>
        </div>
    </p-dialog>
    
    <p-dialog header="Create Folder" [modal]="true" [(visible)]="showFolderCreateDialog" [style]="{ width: '492px' }">
        <input required type="text" pInputText placeholder="Enter Folder Name" [(ngModel)]="newFolderName"
            (keyup.enter)="createFolder()" />
        <div class="d-flex justify-content-end ">
            <button (click)=createFolder() class="p-button-secondary left mt-2" label="Create" pButton pRipple></button>
    
        </div>
    </p-dialog>