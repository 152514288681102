export const environment = {
    firebase: {
        apiKey: "AIzaSyDOs_UqcEVYJ-gdHQr39AV8_o3U7siNyhs",
        authDomain: "recapi-dev.firebaseapp.com",
        projectId: "recapi-dev",
        storageBucket: "recapi-dev.firebasestorage.app",
        messagingSenderId: "898694623511",
        appId: "1:898694623511:web:e5df3835b213d192850752",
        measurementId: "G-W6MBXQDX97"

    },
    // apiUrl: 'https://conn-api.onrender.com/',
    // apiUrl: 'http://localhost:3200/',
    apiUrl: 'https://app.recapi.ai/api/',
    documentAI:'https://beta.meetarise.com/document-ai',
    production: true,
    botEmail: "calendar-dev@meetarise-408100.iam.gserviceaccount.com"
};