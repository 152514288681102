import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Urls } from '../utils/urls';
import { environment } from 'src/environments/environment';
import { MessageService } from 'primeng/api';
import { LoaderService } from '../service/loader.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  date: Date = new Date();
  weekDays: { name: string, date: number }[] = [];
  selectedDayIndex: number = 0;
  upcomingEvents = [];
  scheduleMeetingDialog = false;
  actionItemsCount = {
    open:0,
    closed: 0
  };
  totalMeetingHours = 0;
  totalMeetings = 0
  visible = false;
  meetingData = {
    name: "",
    link: "",
    mom: "",
    status: "",
    createdAt: new Date(),
    attendees: []
  }
  scheduleMeetingOption = [{
    title: "Google Calendar",
    link: `https://calendar.google.com/calendar/u/0/r/eventedit?add=${environment.botEmail}`,
    icon: "/assets/images/google-calendar.png"
  }, {
    title: "Microsoft Outlook Calendar",
    link: `https://outlook.live.com/calendar/0/deeplink/compose?to=${environment.botEmail}`,
    icon: "/assets/images/outlook.png"

  }]
  constructor(private cdr: ChangeDetectorRef, private apiService: ApiService, private messageService: MessageService, private loaderService: LoaderService) {
  }

  ngOnInit(): void {
    this.initializeWeekDays();
    this.fetchAnalyticsData()
  }
  meetings = [
    { title: 'Meeting with Mark Joe', date: new Date(), time: '10:00 am - 11:00 am' },
    { title: 'Meeting with Mark Joe', date: new Date(), time: '10:00 am - 11:00 am' },
    { title: 'Meeting with Mark Joe', date: new Date(), time: '10:00 am - 11:00 am' },
  ];

  scheduleMeeting() {
    // Add logic to schedule a meeting
    console.log('Schedule a meeting');
  }

  addJotter() {
    // Add logic to add a jotter to the live meeting
    console.log('Add Jotter to live meeting');
  }

  uploadAudio() {
    // Add logic to upload audio or transcript
    console.log('Upload audio or transcript');
  }

  recordMeeting() {
    // Add logic to record a meeting
    console.log('Record meeting');
  }
  initializeWeekDays(): void {
    const startOfWeek = new Date();
    startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay() + 1);

    for (let i = 0; i < 7; i++) {
      const date = new Date(startOfWeek);
      date.setDate(startOfWeek.getDate() + i);

      this.weekDays.push({
        name: date.toLocaleString('default', { weekday: 'short' }),
        date: date.getDate()
      });
    }
    this.cdr.detectChanges(); // Trigger change detection manually
    this.selectDay(0)
  }
  selectDay(index: number): void {
    this.selectedDayIndex = index;
    const selectedDate = new Date();
    selectedDate.setDate(new Date().getDate() - new Date().getDay() + 1 + index);

    this.fetchMeetings(selectedDate);
  }
  async fetchMeetings(date: Date) {
    // this.loaderService.loader(true)

    const formattedDate = date.toISOString().split('T')[0];
    try {
      const data: any = await this.apiService.get(`${Urls.dashboardEvent}?date=${formattedDate}`)
      this.upcomingEvents = data?.formattedEvents || [];

    } catch (error) {
      this.loaderService.loader(false)
      console.log("Error" + error)
    }

  }
  onClickScheduleButton(link) {
    const a = document.createElement('a');
    a.href = link;
    a.target = "_blank"
    a.click()
  }
  checkAttendeesEmail(event) {
    if (!this.validateEmail(event.value)) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: "Please enter valid email",
        life: 3000,
      });
      this.meetingData.attendees.pop()
    }
  }
  validateEmail(email: string) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  async saveMeeting() {
    // this.loaderService.loader(true)
    // await this.apiService.put(`${Urls.meeting}/${this.editId}`, {...this.form.value})
    delete this.meetingData.status;
    delete this.meetingData.mom;
    await this.apiService.post(Urls.meeting, { ...this.meetingData })

    this.closeDialog()
    this.messageService.add({
      severity: 'success',
      summary: 'Successful',
      detail: 'Meeting Created.',
      life: 3000,
    });
    this.initData()
  } catch(error) {
    this.closeDialog()
    console.error(error);
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: 'Unable to create Meeting.',
      life: 3000,
    });
  }
  closeDialog() {
    this.visible = false;
    this.loaderService.loader(false)
  }
  async initData() {
    this.loaderService.loader(false);
    // await this.getMeetingList()
  }
  async fetchAnalyticsData() {
    this.loaderService.loader(true);

    try{
      const data: any = await this.apiService.get(Urls.analyticsV2)
      this.actionItemsCount = data.actionItemsCount;
      this.totalMeetings = data.totalMeetings;
      this.totalMeetingHours = data.totalMeetingHours
      this.loaderService.loader(false);
    }catch(error){
      console.error("Error",error)
      this.loaderService.loader(false);
    }

  }
}
