import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-coming-soon',
  templateUrl: './coming-soon.component.html',
  styleUrls: ['./coming-soon.component.scss']
})
export class ComingSoonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  joinWaitlist() {
    alert('Thank you for your interest! We will notify you once we are ready.');
    // You can also redirect to a waitlist sign-up form or page
    // window.location.href = 'https://example.com/waitlist';
  }
}
