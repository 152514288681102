import {  Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { QuerentService } from '../service/querent.service';
import { LoginService } from '../service/login.service';

@Component({
  selector: 'app-querent',
  templateUrl: './querent.component.html',
  styleUrls: ['./querent.component.scss']
})
export class QuerentComponent implements OnInit {
  @Input() multiple: boolean;
  @Input() fileType: string;
  @Input() dragDropEnabled = true;
  @Output() filesChanged: EventEmitter<FileList>;
  @ViewChild('fileInput') inputRef: ElementRef<HTMLInputElement>;

  private patientId!: string;
  messages: Array<any> = [];
  isTyping = false;
  message!: string;
  isSpeaking = true;
  speechData: { voices: any[] } = {
    voices: []
  };
  private speech: any;

  voiceActiveSectionDisabled: boolean = true;
  voiceActiveSectionError: boolean = false;
  voiceActiveSectionSuccess: boolean = false;
  voiceActiveSectionListening: boolean = false;
  voiceText: any;
  voice!: any;
  fontSize = 16
  visibleSidebar2 = false;
  isMicOn = true;
  file: File|any = null;
  fileUploadProgress =0
  userId = 1
  showUploadDocumentModal = false
  fileUploaed = false
  constructor(private qurentService:QuerentService,private loginService:LoginService) {
    this.filesChanged = new EventEmitter();
  }

  ngOnInit(): void {
    const body = document.getElementsByTagName('body')
    body[0].style.overflow='hidden'
    const user = this.loginService.getUser()
    this.userId=user?._id?.toString()
    // this.confirm();
    // this.initTextToSpeech();
    // this.initializeVoiceRecognitionCallback();
  }

  initTextToSpeech() {
    // this.speech = new Speech()
    // if (this.speech.hasBrowserSupport()) {
    //   this.speech.init({
    //     'volume': 1,
    //     'lang': 'en-US',
    //     'rate': 1,
    //     'pitch': 1,
    //     'splitSentences': true,
    //     'listeners': {
    //       'onvoiceschanged': (voices: any) => {
    //       }
    //     }
    //   }).then((data: { voices: any[]; }) => {
    //     this.speechData = data;
    //     if (this.speechData.voices.filter(voice => voice.lang === 'en-US')) {
    //       this.speechData.voices = this.speechData.voices.filter(voice => voice.lang === 'en-US')
    //     }

    //     this.speech.voice = this.speechData.voices[0].name;
    //     this.voice = this.speechData.voices[0];
    //   }).catch((e: any) => {
    //     console.error("An error occured while initializing : ", e)
    //   })
    // } else {
    //   alert('Speech not supported in this browser. for better experience please use chrome or edge')
    //   this.sendConversationMessage("yes", true).catch();
    // }
  }

  async sendConversationMessage(message: string, initConversation: boolean): Promise<void> {
    try {
      this.isTyping = true;
      this.isSpeaking = true;
      this.closeVoiceRecognition();

      const messageBody: any = {
        message: message
      }
      if (this.patientId) {
        messageBody['patient'] = this.patientId;
      }
      if (!initConversation) {
        const doc = {
          type: 'text',
          from: 'user',
          text: message
        }
        this.messages.push(doc);
        this.message = '';
        this.messages = JSON.parse(JSON.stringify(this.messages))
        // this.changeDetectorRef.detectChanges()
      }
      const response:any  = await this.qurentService.chatWithDoc(this.userId,message)
      this.isTyping = false;
      // this.patientId = response.data.patient;
      // if (response.data.message.text.trim() !== '') {
        const doc = {
          type: 'text',
          from: 'bot',
          text:response.response
        }
        this.messages.push(doc);
        this.messages = JSON.parse(JSON.stringify(this.messages))
        // this.changeDetectorRef.detectChanges();
        // this.speakMessage(response.data.message.text).catch()
      // } else {
      //   this.isTyping = false;
      //   this.isSpeaking = false;
        // this.closeVoiceRecognition();
        // this.startVoiceRecognition();
        // this.changeDetectorRef.detectChanges()
      // }
    } catch (err) {
      console.log(err);
    }
  }

  async sendMessage() {
    try {
      if (this.message.trim() !== "") {
        await this.sendConversationMessage(this.message, false);
        this.message = ""
      }
    } catch (err) {
      console.log(err);
    }
  }

  async speakMessage(message: string) {
    this.speech.setLanguage(this.voice.lang);
    this.speech.setVoice(this.voice.name);
    this.speech.speak({
      text: message,
      queue: false,
      listeners: {
        onstart: () => {
        },
        onend: () => {
        },
        onresume: () => {
        }
      }
    }).then((data: any) => {
      this.isSpeaking = false;
      this.startVoiceRecognition()
      // this.changeDetectorRef.detectChanges();
    }).catch((e: any) => {
      console.error("An error occurred :", e)
    })
  }

  initializeVoiceRecognitionCallback(): void {
    // annyang.addCallback('error', (err: any) => {
    //   if (err.error === 'network') {
    //     this.voiceText = "Internet is require";
    //     annyang.abort();
    //     this.voiceActiveSectionSuccess = true
    //   } else if (this.voiceText === undefined) {
    //     this.voiceActiveSectionError = true
    //     annyang.abort();
    //   }
    // });

    // annyang.addCallback('soundstart', (res: any) => {
    //   this.voiceActiveSectionListening = true
    // });

    // annyang.addCallback('end', (userSaid: any) => {
    //   if (this.voiceText === undefined) {
    //     this.voiceActiveSectionError = true
    //     annyang.abort();
    //   }
    // });

    // annyang.addCallback('result', async (userSaid: any) => {
    //   this.voiceActiveSectionError = false

    //   let queryText: any = userSaid[0];

    //   annyang.abort();
    //   this.voiceActiveSectionListening = false
    //   this.voiceActiveSectionSuccess = true
    //   if (queryText.trim()) {
    //     this.message = queryText;
    //     this.changeDetectorRef.detectChanges();
    //     setTimeout(() => {
    //       this.closeVoiceRecognition();
    //       this.sendConversationMessage(queryText, false).catch()
    //     }, 500)
    //   }
    // });
  }

  startVoiceRecognition(): void {
    this.voiceActiveSectionDisabled = false;
    // this.voiceActiveSectionError = false;
    // this.voiceActiveSectionSuccess = false;
    // this.voiceText = undefined;

    // if (annyang) {
    //   let commands = {
    //     'demo-annyang': () => {
    //     }
    //   };
    //   annyang.addCommands(commands);
    //   annyang.start({autoRestart: true});
    // }
  }

  closeVoiceRecognition(): void {
    this.voiceActiveSectionDisabled = true;
    // this.voiceActiveSectionError = false;
    // this.voiceActiveSectionSuccess = false;
    // this.voiceActiveSectionListening = false;
    // this.voiceText = undefined;

    // if (annyang) {
    //   annyang.abort();
    // }
  }

  confirm() {
    // this.confirmationService.confirm({
    //   message: 'For better bot experience allows ?',
    //   header: "Hello, I'm Hospital Assistant",
    //   icon: '',
    //   accept: () => {
    //     this.sendConversationMessage("yes", true).catch()
    //   },
    //   reject: () => {
    //     this.sendConversationMessage("yes", true).catch()
    //   }
    // });
  }

  increaseFont() {
    this.fontSize++
    document.documentElement.style.setProperty('--chatFontSize', this.fontSize + 'px');
  }

  decreaseFont() {
    this.fontSize--
    document.documentElement.style.setProperty('--chatFontSize', this.fontSize + 'px');
  }

  changeMicType() {
    if (this.isSpeaking) {
      return;
    }
    this.isMicOn = !this.isMicOn
    if (this.isMicOn) {
      this.startVoiceRecognition();
    } else {
      this.closeVoiceRecognition()
    }
  }
  async addFiles(files: FileList): Promise<void> {
    try{
      let formParams = new FormData();
      // this.file={
      //   name:"Uploading..."
      // }
      formParams.append('files', files[0])
      await this.qurentService.uploadDocument(formParams,this.userId)
      this.file= files[0]
      this.filesChanged.emit(files);
          }catch(error){
      console.error("Error in file upload");
      this.file={
        name:"Error in file upload,Please try again."
      }
    }
   
  }
  handleFileDrop(event: DragEvent) {
    if (event?.dataTransfer?.files?.length) {
      const files = event.dataTransfer.files;
      this.inputRef.nativeElement.files = files;
      this.addFiles(files);
    }
  }
  selectFileUpload(){
    let element = document.getElementById('fileInput') as HTMLInputElement;
    element.click();
  }
}
