<p-toast></p-toast>
<!-- <app-header></app-header>

<div class="flex align-items-center justify-content-center min-h-screen overflow-hidden bg-accent">
    <div class="grid justify-content-center p-2 lg:p-0 " style="min-width: 60%">
        <div class="col-12 xl:col-6 bg-white shadow-sm rounded-md" style="
                padding: 0.3rem;
                /* background-color: aliceblue; */
                /* background: linear-gradient(
                    180deg,
                    var(--primary-color) 10%,
                    rgba(55, 163, 251, 0.196) 30%
                ); */
            ">
            <div class="h-full w-full m-0 py-3 px-4">
                <div class="text-center">
                    <h1 class="text-900 font-bold text-4xl lg:text-5xl mb-2 color-primary">Sign In to Meetarise </h1>
                    <span class="text-600 font-medium">To continue, please enter your details below and click on <b>Sign
                            In</b>.</span>
                </div>
                <form [formGroup]="loginForm">
                    <div class=" row">
                        <div class="p-field">
                            <label class="block text-900  font-medium my-2" for="email">Email*</label>
                            <input style="width:100%" autofocus id="email" name="email" pInputText required
                                formControlName="email" type="text" />
                            <div [hidden]="loginForm.controls['email'].valid || !loginForm.controls['email'].dirty"
                                class="alert alert-danger text-danger">
                                Email is required
                            </div>
                        </div>



                        <div class="p-field">
                            <label class="block text-900 font-medium my-2" for="password">Password*</label>
                            <input style="width:100%" autofocus id="password" name="password" pInputText required
                                formControlName="password" type="password" />
                        </div>
                        <span routerLink="/forgot-password"
                            class="btn-link --primary-color transition-all color-primary d-flex justify-content-end pt-3">Forgot
                            your password? </span>
                        <div class="w-full md:w-10 mx-auto mt-5">
                            <button [loading]="isLoader" (click)="submit()" pButton pRipple label="Sign In"
                                class="w-full p-2 text-xl bg-primary border-0">
                            </button>
                        </div>
                        <h6 style="margin-top: 13px;">
                            Or Continue With
                        </h6>
                    </div>
                    <div class="w-full md:w-10 mx-auto">
                        <button (click)="signIn()" pButton pRipple label="Google"
                            class="login-with-google-btn w-full text-xl bg-white border-radius border-t">
                            <i class="pi pi-google"></i>
                        </button>
                    </div>
                    <div class="text-sm text-mute d-flex align-items-center justify-content-center p-2 ">
                        Don’t have an account?
                        &nbsp;
                        <span routerLink="/signup"
                            class="btn-link --primary-color transition-all color-primary">Sign-up</span>
                    </div>
                </form>
                <div class="text-sm text-mute d-flex align-items-center justify-content-center p-2 ">
                    By using Meetarise you agree to the <a href="https://meetarise.com/privacy-policy" class="btn-link --primary-color transition-all color-primary"> Terms of Servicep </a>
                    &nbsp; and    &nbsp; <a href="https://meetarise.com/privacy-policy"class="btn-link --primary-color transition-all color-primary"> Privacy Policy</a>

                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- login.component.html -->
<div class="container">
    <div class="left-side">
        <div class="logo">
            <img src="/assets/images/recapi-logo.png" alt="Recapi Logo">
        </div>
        <img src="/assets/images/meetarise_overview.png" alt="Recapi Overview" class="overview-image">
    </div>
    <div class="right-side">
        <h1>Hello Again!</h1>
        <p class="tagline">Welcome Back</p>
        <form class="login-form" [formGroup]="loginForm">
            <div class="input-group">
                <input formControlName="email" type="email" required placeholder="Email Address">

            </div>
            <div class="input-group">
                <input formControlName="password" type="password" required placeholder="Password">
            </div>
            <a routerLink="/forgot-password" class="forgot-password ">Forgot Password?</a>
            <div class="d-flex justify-content-center">
                <button type="submit" class="login-button" (click)="submit()">Login</button>

            </div>
            <div class="divider">or</div>
            <div class="d-flex justify-content-center">
                <button type="button" class="google-login-button"  (click)="signIn()" >
                    <img src="https://developers.google.com/static/identity/images/g-logo.png" alt="Google Icon"> Google
                    Login
                </button>
            </div>

        </form>
        <p class="create-account">Don’t have an account? <a routerLink="/signup">Create</a></p>
    </div>
</div>