<p-toast></p-toast>

<div class="layout-topbar">
    <a class="layout-topbar-logo" routerLink="">
        <img src="assets/images/logo.png" alt="CONN" />
    </a>

    <a class="p-link layout-menu-button layout-topbar-button" href="#" (click)="appMain.toggleMenu($event)">
        <i class="pi pi-bars"></i>
    </a>
    <div class="search-container">
        <input pInputText type="text" placeholder="Search">
        <i class="pi pi-search"></i>
      </div>
    <div class="d-flex">
        <div class="d-flex" style="align-items: center; padding-right: 35px;">
            <i class="pi pi-credit-card" style="padding-right: 3px;"></i>
            <span class="p-top-licence">{{licence?.totalHours-licence?.usedHours | number : '1.2-2'}} hours
                left</span>/<span style="font-size: 15.5px;
            color: rgb(107, 111, 118);">{{ (licence?.totalHours?licence?.totalHours:0) | number : '1.2-2'
                }}hours</span>
        </div>
        <a class="p-link layout-topbar-menu-button layout-topbar-button" href="#"
            (click)="appMain.toggleTopMenu($event)">
            <div *ngIf="!user.profileImg" class="profile-icon" [style.backgroundColor]="backgroundColor">
                {{ initials }}
            </div>
            <img *ngIf="user.profileImg" src="{{ user.profileImg }}"
                style="height: 42px; width: 42px; border-radius: 42px" alt="logo" />
        </a>
    </div>
    <!-- <div>
        Record
    </div> -->

    <div class="layout-topbar-menu layout-topbar-menu-mobile-active" [ngClass]="{
            'layout-topbar-menu-mobile-active': appMain.topMenuActive
        }">
        <a class="p-link layout-topbar-button" (click)="logout()">
            <i class="pi pi-sign-out"></i>
            <span>Logout</span>
        </a>
    </div>

</div>
<div *ngIf="!isPlanExpireInFewDays && !visibleDialog" style="margin-top: 6%;">
    <p-messages [closable]="false" [enableService]="false" severity="error">
        <ng-template pTemplate>
            <!-- <img src="https://primefaces.org/cdn/primeng/images/primeng.svg" width="32" /> -->
            <div class="ml-2">{{expireMessage}}</div> 
        <br>
            <a href="https://calendly.com/devanjan/introductory-call"> &nbsp;Click here for Upgrade</a>
        </ng-template>
    </p-messages>

</div>
<p-dialog [(visible)]="visibleDialog" [breakpoints]="{ '960px': '85vw' }" [draggable]="false" [modal]="true" [closable]="false"
    [style]="{width: '35vw'}" header="Subscription Expired">
    <div >
        <div  class="d-flex align-items-center justify-content-center">
            <img src="assets/images/expired.png" width="50" alt="Upgrade"/>

        </div>
        <div>
            <h3 class="font-semibold mt-2 d-flex align-items-center justify-content-center">Oops, seems like your subscription is expired.</h3>
            <p class=" text-gray-800 mt-1 text-center d-flex align-items-center justify-content-center">
                Upgrade your subscription to experience the full potential of our services!
            </p>
            <div class="d-flex align-items-center justify-content-center">
                <button pButton pRipple label="Upgrade" class="p-button-secondary" (click)="openLink('https://calendly.com/devanjan/introductory-call')"></button>

            </div>
        </div>
       

    </div>
</p-dialog>