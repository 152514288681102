import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss']
})
export class FilesComponent implements OnInit {
  @Input() allFiles: string;
  @Input()isFile: boolean;
  @Output() fileFolderSelect = new EventEmitter<string>();

  selectedFolder:string;
  filesExtension = {
    docs:['docx','docs'],
    pdf:['pdf'],
    csv:['csv']
  }
  constructor() { }

  ngOnInit(): void {
  }
  selectFolderFile(file){
    if(!this.isFile){
      this.selectedFolder = file;
    }else{
      this.selectedFolder = null;
    }
    this.fileFolderSelect.emit(file);

  }
}
