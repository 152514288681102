import {environment} from '../../../../environments/environment';
import {Component, OnInit} from '@angular/core';
import {HttpParams} from "@angular/common/http";
import {ApiService} from "../../../service/api.service";
import {Urls} from "../../../utils/urls";
import {MessageService} from "primeng/api";
import {LoaderService} from "../../../service/loader.service";
import {UtilsService} from "../../../service/utils.service";

@Component({
    selector: 'app-connection-list',
    templateUrl: './connection-list.component.html',
    styleUrls: ['./connection-list.component.scss']
})
export class ConnectionListComponent implements OnInit {
    connections: any = []
    dateRange: Date[];
    totalDocs: number = 0;
    searchValue:string=''
    displayConnectionSidebar: boolean = false;
    editConnectionId: string;
    meetingData = {
        name:"",
        link:"",
        mom:"",
        action:"",

    }
    constructor(private readonly apiService: ApiService, private messageService: MessageService, private loaderService: LoaderService, readonly utilsService: UtilsService) {
    }

    ngOnInit(): void {
        this.getConnections({rows: 10, first: 0})
    }

    async getConnections(event) {
        const params = {
            limit: event.rows,
            page: (event.first / event.rows) + 1,
            sortOrder: event.sortOrder,
            sortField: event.sortField,
            filter: event.globalFilter ? event.globalFilter : ''
        }
        this.loaderService.loader(true);
        const res: any = await this.apiService.get(Urls.meeting, {params: params as unknown as HttpParams})
        this.loaderService.loader(false);
        this.connections = res.data.docs;
        if(this.connections.length){
        }
        this.totalDocs = res.data.totalDocs;
    }

    onClose(event: boolean = false) {
        this.displayConnectionSidebar = false;
        this.editConnectionId = null;
        if (event) {
            this.getConnections({rows: 10, first: 0})
        }
    }

    editConnection(event, _id) {
        event.stopPropagation();
        this.editConnectionId = _id;
        this.displayConnectionSidebar = true;

    }

    async deleteConnection(event, _id: any) {
        event.stopPropagation();
        try {
            this.loaderService.loader(true);
            await this.apiService.delete(`${Urls.connection}/${_id}`)
            this.messageService.add({
                severity: 'success',
                summary: 'Successful',
                detail: 'Connection deleted successfully',
                life: 3000,
            });
            this.loaderService.loader(false);
        } catch (e) {
            this.loaderService.loader(false);
            this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Unable to delete connection.',
                life: 3000,
            });
            console.error(e);
        }
    }

    copyLink(event: MouseEvent, _id: any) {
        event.stopPropagation();
        this.utilsService.copyToClipboard(`${environment.apiUrl}${_id}`)
    }
    getMeetingDetails(data){
        this.meetingData =data
        this.displayConnectionSidebar = true
    }
    onSearch(){
        this.displayConnectionSidebar=false
        this.getConnections({rows: 10, first: 0,
            globalFilter:this.searchValue
        })
    }

    formatDate(date: Date): string {
        const options = { year: 'numeric', month: 'short', day: 'numeric' } as const;
        return new Date(date).toLocaleDateString('en-US', options);
      }
    
      viewNotes(meeting: any) {
        this.meetingData =meeting
        this.displayConnectionSidebar = true      }
    
      pushToJira(meeting: any) {
        // Handle pushing to Jira
      }
    
      expandReport(meeting: any) {
        // Handle expanding report
      }
}
