import { Component, OnInit } from '@angular/core';
import {ApiService} from "../service/api.service";
import { Urls } from '../utils/urls';
import {LoaderService} from "../service/loader.service";

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent implements OnInit {
  overallChartData: any;
  highPriorityChartData: any;
  chartOptions: any;
  actionItems: any[] = [];
  timeframes: any[] = [];
  projects: any[] = [];
  assignees: any[] = [];
  selectedTimeframe: any;
  selectedProject: any;
  selectedAssignee: any;

  constructor(private readonly apiService: ApiService, private loaderService: LoaderService) {
    this.chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      cutout: '80%',
      plugins: {
        legend: {
          display: true,
          position: 'bottom'
        }
      }
    };

    this.timeframes = [
      { label: 'This month', value: 'this_month' },
      { label: 'Last month', value: 'last_month' },
      { label: 'This year', value: 'this_year' }
    ];

    this.projects = [
      { label: 'All Projects', value: null },
      { label: 'Texas Instrument pvt ltd', value: 'texas_instrument' },
      { label: 'Onida electronics', value: 'onida_electronics' },
      { label: 'Biju consultant', value: 'biju_consultant' }
    ];

    this.assignees = [
      { label: 'All Assignees', value: null },
      { label: 'Alex mat', value: 'alex_mat' },
      { label: 'Rinku singh', value: 'rinku_singh' },
      { label: 'Jordan Adolf', value: 'jordan_adolf' },
      { label: 'Jimson kait', value: 'jimson_kait' },
      { label: 'Yuvraaj singh', value: 'yuvraaj_singh' }
    ];
  }

  totalMeetings: number;
  totalMeetingHours: number;
  totalParticipants: number;


  ngOnInit(): void {
    this.fetchAnalyticsData();
  }

  async fetchAnalyticsData() {
    this.loaderService.loader(true);

    try{
      const data: any = await this.apiService.get(Urls.analyticsV2)
      this.overallChartData = {
        labels: ['Open action item', 'Overdue action item'],
        datasets: [
          {
            data: [data.actionItemsCount.open, data.actionItemsCount.closed],
            backgroundColor: ['#42A5F5', '#FFA726'],
            borderWidth: 1,
            hoverBorderWidth: 3
          }
        ]
      };
      const labels = []
      const countPriority = []
      const color = []
      data.actionItemriorityCounts.forEach(element => {
        labels.push(element.priority);
        countPriority.push(element.count)
        color.push(element.color)
      });
      this.highPriorityChartData = {
        labels: labels,
        datasets: [
          {
            data:countPriority, // Placeholder values
            backgroundColor: color,
            borderWidth: 1,
            hoverBorderWidth: 3
          }
        ]
      };

      this.actionItems = data.actionItems;
      this.totalMeetings = data.totalMeetings;
      this.totalMeetingHours = data.totalMeetingHours;
      this.totalParticipants = data.totalParticipants;
      this.loaderService.loader(false);
    }catch(error){
      console.error("Error",error)
      this.loaderService.loader(false);
    }

  }
}