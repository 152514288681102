import {Component, OnInit} from '@angular/core';
import {AppMainComponent} from './app.main.component';
import {LoginService} from "./service/login.service";

@Component({
    selector: 'app-menu',
    styles:[`.sidebar {
        width: 160px;
        height: 100vh;
        background-color: white; /* Set background to white */
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1rem 0;
        position: fixed;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for better visibility */
      }
      
      .logo img {
        width: 60px; /* Adjusted logo size */
        margin-bottom: 1.5rem; /* More space below the logo */
      }
      
      nav {
        width: 100%;
      }
      
      nav ul {
        list-style: none;
        padding: 0;
        margin: 0;
      }
      
      nav ul li {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1rem 0; /* Padding for better spacing */
        cursor: pointer;
        transition: background-color 0.3s;
      }
      
      nav ul li:hover {
        background-color: #f0f0f0; /* Lighter hover color for better visibility */
      }
      
      nav ul li.active {
        background-color: #e0e0e0; /* Lighter active color for better visibility */
      }
      
      nav ul li i {
        font-size: 2rem; /* Larger icon size */
        margin-bottom: 0.5rem; /* Space between icon and text */
      }
      
      nav ul li span {
        font-size: 1rem;
      }
      
      @media (max-width: 768px) {
        .sidebar {
          width: 100%;
          height: auto;
          flex-direction: row;
          justify-content: space-around;
          padding: 0.5rem 0;
        }
      
        nav ul {
          display: flex;
        }
      
        nav ul li {
          padding: 0.5rem;
        }
      
        nav ul li i {
          margin-bottom: 0; /* Remove margin for smaller screens */
          font-size: 1.5rem; /* Adjust icon size for smaller screens */
        }
      
        nav ul li span {
          font-size: 0.875rem; /* Adjust text size for smaller screens */
        }
      }
      
      .sidebar-selected {
        background-color: #f0f0f0; /* Lighter hover color for better visibility */
      }
      `],
    template: `
    <div class="sidebar" (keydown)="onKeydown($event)" *ngFor="let item of model; let i = index;" [item]="item">
    <nav>
      <ul>
        <li routerLink="{{child.routerLink}}" *ngFor="let child of item.items; let i = index" (click)="selectedDayIndex=i" [ngClass]="{'sidebar-selected': i == selectedDayIndex }"  [item]="child">
          <i class="{{child.icon}}"></i>
          <span>{{child.label}}</span>
        </li>
      </ul>
    </nav>
  </div>
  
    `
})
export class AppMenuComponent implements OnInit {
    selectedDayIndex=0
    model: any[];
    modelSetting:any[];
    constructor(public loginService: LoginService) {
    }

    ngOnInit() {
        this.model = [
            {
                items: [
                    {label: 'Dashboard', icon: 'pi pi-fw pi-home', routerLink: ['/']},
                    {label: 'Library', icon: 'pi pi-folder', routerLink: ['meetings']},
                    // {label: 'Configuration', icon: 'pi pi-calendar', routerLink: ['meetings']},
                    {label: 'Analytics', icon: 'pi pi-chart-line', routerLink: ['analytics']},
                    {label: 'Integrations', icon: 'pi pi-th-large', routerLink: ['integration']},
                    {label: 'Querent', icon: 'pi pi-discord', routerLink: ['chat']},
                    {label: 'Brainiac', icon: 'pi pi-prime', routerLink: ['coming-soon']},
                    {label: 'Settings', icon: 'pi pi-cog', routerLink: ['setting']},

                ]
            }
        ];
        this.modelSetting = [
            {
                items: [
                    {label: 'Settings', icon: 'pi pi-fw  pi-cog', },
                    {label: 'Profile', icon: 'pi pi-fw pi-users', },
                    {label: 'Logout', icon: 'pi pi-fw pi-sign-out',},

                ]
            }
        ];
        const user = this.loginService.getUser();
        if (user && user.role === 'admin') {
            this.model[0].items.push({label: 'Licences', icon: 'pi pi-users', routerLink: ['licence']})
        }

    }

    onKeydown(event: KeyboardEvent) {
        const nodeElement = (<HTMLDivElement>event.target);
        if (event.code === 'Enter' || event.code === 'Space') {
            nodeElement.click();
            event.preventDefault();
        }
    }
}
