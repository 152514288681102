<!-- <div class="main-container"> -->
<div class="grid quarant-cover">
    <div class="col-12 md:col-8 image-div">
        <div style="height: 100%; display: flex; flex-direction: column;">
            <h2>Files & Folders</h2>
            <!-- <p class="lead">Supported formats <b>PDF,docx,TXT</b></p> -->
            <!-- Upload  -->
            <div class="files-cover">
                <button
                    (click)="showUploadDocumentModal = true"
                    class="blue-btn cursor-pointer upload-btn"
                >
                    <img src="/assets/images/file-upload-btn.svg" height="18" width="18" alt="Icon" />
                    Upload Document
                </button>

                <div>
                    <div *ngIf="file && fileUploaed" class="uploaded-file">
                        <img src="/assets/images/pdf-icon.svg" height="24" width="24" alt="Icon" />
                        <p>{{ this.file.name }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 md:col-4 chat-box">
        <div class="conversation">
            <div class="conversation-label">
                <p>Interact here</p>
            </div>
            <ng-container *ngFor="let message of messages; index as index">
                <div class="bot-message" *ngIf="message.from === 'bot'">
                    <p class="label">Assistant</p>
                    <p class="text">
                        {{ message.text }}
                    </p>
                </div>
                <div class="user-message" *ngIf="message.from === 'user'">
                    <p class="text">
                        {{ message.text }}
                    </p>
                </div>
            </ng-container>
            <div class="bot-typing" *ngIf="isTyping">
                <div id="wait">
                    <div class="loader--container">
                        <div class="spinner">
                            <div class="bounce1"></div>
                            <div class="bounce2"></div>
                            <div class="bounce3"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="message-input flex align-self-end">
            <!-- <div class="settings" (click)="visibleSidebar2=true">
          <div></div>
          <div></div>
          <div></div>
        </div> -->
            <input
                type="text"
                pInputText
                placeholder="Reply ..."
                [(ngModel)]="message"
                (keyup.enter)="sendMessage()"
            />
            <div class="action-div" (click)="sendMessage()">
                <img src="/assets/images/arrow-right.svg" height="12" width="6" alt="Icon" />
            </div>
        </div>
    </div>
</div>
<!-- </div> -->

<p-confirmDialog
    [style]="{ width: '50vw' }"
    [baseZIndex]="10000"
></p-confirmDialog>

<p-sidebar [(visible)]="visibleSidebar2" position="right" [baseZIndex]="10000">
    <div class="slider-main-container">
        <div class="voice-main-container">
            <div class="title mb-1">Voice</div>
            <p-dropdown
                [options]="speechData.voices"
                [(ngModel)]="voice"
                placeholder="Select a Voice"
                optionLabel="name"
            ></p-dropdown>
        </div>
        <div class="chat-font-size">
            <div class="title">Chat font size</div>
            <div class="container">
                <div class="size-decrease" (click)="decreaseFont()">
                    <i class="pi pi-minus icon-action"></i>
                </div>
                <div class="font-display">
                    <span>{{ fontSize + "px" }}</span>
                </div>
                <div class="size-increase" (click)="increaseFont()">
                    <i class="pi pi-plus icon-action"></i>
                </div>
            </div>
        </div>
    </div>
</p-sidebar>

<p-dialog
    header="Upload files"
    [modal]="true"
    [(visible)]="showUploadDocumentModal"
    [style]="{ width: '492px' }"
>
    <div
        id="file-upload-form"
        class="uploader"
        [appDragDrop]="dragDropEnabled"
        (dropped)="handleFileDrop($event)"
    >
        <input
            type="file"
            [accept]="fileType"
            id="fileInput"
            [multiple]="multiple"
            (change)="addFiles(fileInput.files)"
            #fileInput
            class="visually-hidden"
        />

        <label for="file-upload" id="file-drag" class="file-upload-label">
            <div id="start">
                <img src="/assets/images/file-upload.svg" height="60" width="68" alt="Icon" />
                <p class="font-bold heading">
                    Drag & drop files or
                    <span
                        (click)="selectFileUpload()"
                        class="underline cursor-pointer"
                        >Browse</span
                    >
                </p>
                <p class="sub-text">Supported formats: Pdf, XLS, ppt, docs</p>
            </div>
            <div id="response" *ngIf="fileUploadProgress">
                <div id="messages"></div>
                <p-progressBar [value]="fileUploadProgress"></p-progressBar>
            </div>
        </label>
        <div class="">
            {{ this.file?.name }}
        </div>
    </div>
    <div class="upload-actions">
        <button
            label="Cancel"
            severity="secondary"
            (click)="showUploadDocumentModal = false"
            class="cancel-btn cursor-pointer"
        >
            Cancel
        </button>
        <button
            label="Save"
            (click)="showUploadDocumentModal = false; fileUploaed = true"
            class="blue-btn ml-4 cursor-pointer"
        >
            Upload
        </button>
    </div>
</p-dialog>
