import { Component, OnInit } from '@angular/core';
// import { ConfigService } from '../../service/app.config.service';
import { LoginService } from 'src/app/service/login.service';
import { Router } from '@angular/router';
import { map } from 'rxjs';
import { MessageService } from 'primeng/api';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
   

    constructor(
        // public configService: ConfigService,
        private loginService: LoginService,
        private router: Router,
        private messageService: MessageService
    ) {
    }

    ngOnInit(): void {
    }}