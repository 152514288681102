import {Injectable} from '@angular/core';
import {Auth, UserCredential} from '@angular/fire/auth';
import {GoogleAuthProvider, signInWithPopup} from 'firebase/auth';
import {from, Observable} from 'rxjs';
import {Router} from '@angular/router';
import {environment} from 'src/environments/environment';
import {ApiService} from './api.service';
import {Urls} from "../utils/urls";

@Injectable({
    providedIn: 'root',
})
export class LoginService {

    OTP_TYPE = {
        SIGNUPOTP:'signupOtp',
        FORGETPASSOTP:'forgetPassOtp'
      
      };

    constructor(
        private auth: Auth,
        private router: Router,
        private apiService: ApiService
    ) {
    }

    googleSignIn(): Observable<UserCredential> {
        return from(signInWithPopup(this.auth, new GoogleAuthProvider()));
    }

    googleSignOut() {
        this.auth.signOut();
        this.apiService.get(Urls.logout);
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.clear();
        this.router.navigate(['login']);
    }

    setSignInDetails(data: any) {
        localStorage.setItem('user', JSON.stringify(data['user']));
    }

    setToken(data: any) {
        localStorage.setItem('token', data['token']);
    }

    getToken() {
        // return  true
        return localStorage.getItem('token');
    }

    getUser() {
        // return  {
        //     role:'admin',
        //     token: "",
        //     uId: "",
        //     name: "",
        //     email: "msb6531@gmail.com",
        //     phoneNo: "919016774412",
        //     profileImg: "https://miro.medium.com/v2/resize:fit:2400/1*wBMqH-6oYyZ5OfjE9bmfRQ.jpeg",
        // }
        return JSON.parse(localStorage.getItem('user'));
    }

    async sendData(data) {
        return this.apiService.post(Urls.login, data, {
            headers: {
                authorization: data.token,
                uid: data.uId,
            }
        });
    }
    async getLicence(){
        try{
            const licence:any = await this.apiService.get(Urls.licence);
            if(licence){
                return licence.data
            }
        }catch(error){
            console.error("Error",error)
        }
    }
    setLicence(licence){
        localStorage.setItem('licence', JSON.stringify(licence));
    }
    getLicenceLocal(){
        localStorage.getItem('licence');
    }
    async loginWithEmailPassowrd(data:{email:string,password:string}){
        return this.apiService.post(Urls.authLogin,data)
    }
    async Register(data:any){
        return this.apiService.post(Urls.authSignUp,data)

    }
    async verifyOTP(data:{email:string,code:string}){
        return this.apiService.post(Urls.verifyOtp,data)
    }
    async resendOtp(data:{email:string,type:string}){
        return this.apiService.post(Urls.resendOtp,data)
    }
    async otpverfy(data:{email:string}){
        return this.apiService.post(Urls.resendOtp,data)
    }
    async forgetpassword(data:{email:string}){
        return this.apiService.post(Urls.forgetpassword,data)
    }
    async resetPassword(data:{email:string,code:string,password:string}){
        return this.apiService.post(Urls.resetPassword,data)
    }
}

