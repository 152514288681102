<div class="bg-secondary flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
    <div class="grid justify-content-center p-2 lg:p-0" style="min-width:80%">
        <div class="col-12 mt-5 xl:mt-0 text-center">
            <img src="assets/images/logo.png" alt="Sakai logo" class="mb-5 mt-3" style="height:60px;">
        </div>
        <div class="col-12 xl:col-6"
             style="border-radius:56px; padding:0.3rem; background: linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%);">
            <div class="flex justify-content-center h-full w-full m-0 py-7 px-4"
                 style="border-radius:53px; background: var(--white-color);">
                <div class="grid flex-column align-items-center">
                    <span class="font-bold text-3xl">404</span>
                    <h1 class="text-900 font-bold text-3xl lg:text-5xl mb-2 color-primary">Looks like you are lost</h1>
                    <span class="text-600">Requested resource is not available.</span>
                    <div class="col-12 mt-5 text-center color-primary">
                        <i class="pi pi-fw pi-arrow-left mr-2" style="vertical-align:center;"></i><a href="#">Go to
                        Dashboard</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
