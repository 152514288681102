import {Component, OnInit} from '@angular/core';
import {ApiService} from "../../../service/api.service";
import {LoaderService} from "../../../service/loader.service";
import {IResponse} from "../../../interface/common";
import {IAnalytics} from "../../../interface/analytics";
import {Urls} from "../../../utils/urls";
import {HttpParams} from "@angular/common/http";
import * as moment from "moment/moment";
import {ActivatedRoute} from "@angular/router";

@Component({
    selector: 'app-connection-analytics',
    templateUrl: './connection-analytics.component.html',
    styleUrls: ['./connection-analytics.component.scss']
})
export class ConnectionAnalyticsComponent implements OnInit {

    chartData: any;
    chartOptions: any = {
        scales: {
            y: {min: 0},
        },
        alignTicks: false,
    };
    selectedConnection: String;
    lastMonthDate = new Date(new Date().getFullYear(), new Date().getMonth() - 1, new Date().getDate());
    selectedRange: Date[] = [this.lastMonthDate, new Date()];
    totalFallback: number = 0;
    totalHandled: number = 0;
    totalRequests: number = 0;
    topIntent = [];
    topMessage = [];
    topFallback = [];

    constructor(private readonly apiService: ApiService, private loaderService: LoaderService, private route: ActivatedRoute) {
    }

    async ngOnInit() {
        this.initData().catch();
    }

    async initData() {
        this.loaderService.loader(true);
        // await this.getConnectionNames();
        this.selectedConnection = this.route.snapshot.paramMap.get('id');
        await this.getAnalytics()
    }

    async getAnalytics() {
        if (!this.selectedConnection || !this.selectedRange || !this.selectedRange[0] || !this.selectedRange[1]) {
            return false;
        }
        try {
            this.loaderService.loader(true);
            const params = {
                start: this.selectedRange[0].toISOString(),
                end: this.selectedRange[1].toISOString(),
            }
            const response: IResponse<IAnalytics> = (await this.apiService.get(`${Urls.connection}/${this.selectedConnection}/analytics`, {params: params as unknown as HttpParams})) as unknown as IResponse<IAnalytics>;
            this.totalRequests = response.data.totalReq;
            this.totalFallback = response.data.totalFallback;
            this.totalHandled = response.data.totalHandled;
            this.chartData = await this.getChartData(response.data);
            this.topIntent = Object.keys(response.data.intent).map(key => {
                return {
                    name: key,
                    value: response.data.intent[key]
                }
            });
            this.topMessage = Object.keys(response.data.message).map(key => {
                return {
                    name: key,
                    value: response.data.message[key]
                }
            })
            this.topFallback = Object.keys(response.data.fallbackMsg).map(key => {
                return {
                    name: key,
                    value: response.data.fallbackMsg[key]
                }
            })

        } catch (e) {
            console.error(e);
        } finally {
            this.loaderService.loader(false)
        }
        return true;
    }

    async getChartData(data: IAnalytics) {
        const labels = this.getDates(this.selectedRange[0], this.selectedRange[1]);
        const value = {}

        labels.forEach(label => {
            if (!value[label]) {
                value[label] = 0;
            }
        });

        for (const key in data.dateWiseCount) {
            const date = moment(key).format('DD-MM-YY')
            value[date] = data.dateWiseCount[key] || 0;
        }

        const keys = Object.keys(value);
        const values = Object.values(value);
        return {
            labels: keys,
            datasets: [
                {
                    label: 'Requests',
                    data: values,
                    fill: false,
                    backgroundColor: '#2f4860',
                    borderColor: '#2f4860',
                    tension: .4
                }
            ]
        };
    }

    getDates(startDate, endDate) {
        const dateArray = [];
        let currentDate = moment(startDate);
        let stopDate = moment(endDate);
        while (currentDate <= stopDate) {
            dateArray.push(moment(currentDate).format('DD-MM-YY'))
            currentDate = moment(currentDate).add(1, 'days');
        }
        return dateArray;
    }
}
