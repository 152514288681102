<div class="grid">
    <div class="col-12">
        <div class="card p-0">
            <p-toolbar>
                <ng-template pTemplate="left">
                    <div>
                        <span class="p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input type="text" pInputText placeholder="Search" (input)="dt.filterGlobal($event?.target?.value, 'contains')">
                        </span>
                    </div>
                </ng-template>
            </p-toolbar>
            <p-table #dt [value]="allLicence" responsiveLayout="scroll" [lazy]="true"  (onLazyLoad)="getUsers()"
                     [paginator]="true" [rows]="10" [totalRecords]="totalDocs">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <!-- <th>Connections</th> -->
                        <!-- <th pSortableColumn="createdAt">Created At
                            <p-sortIcon field="createdAt"></p-sortIcon>
                        </th> -->
                        <th>Last Login</th>

                        <th>Total Hours</th>
                        <th>Used Hours</th>
                        <th>Amount($)</th>
                        <th>Action</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-licence let-columns="columns">
                    <tr>
                        <td>
                            <!-- <p-avatar size="large"></p-avatar> -->
                            {{licence?.userId?.name}}
                        </td>
                        <td>{{licence?.userId?.email}}</td>
                        <!-- <td>{{user.connections}}</td> -->
                        <!-- <td>{{licence?.createdAt | date: 'DD/MM/YYYY'}}<br>{{licence.createdAt | date: 'shortTime'}}</td> -->
                        <td>{{licence?.userId?.lastLogin | date :'short'}}</td>
                        <td>{{licence?.yearlyData[0]?.totalHours}}</td>
                        
                        <td>
                            <span *ngIf="licence?.yearlyData[0]?.totalHours < licence?.yearlyData[0]?.usedHours"
                                [class]="'status-instock'"> {{licence?.yearlyData[0]?.usedHours | number}}</span>
                                <span *ngIf="licence?.yearlyData[0]?.totalHours > licence?.yearlyData[0]?.usedHours"
                                [class]="''"> {{licence?.yearlyData[0]?.usedHours | number}}</span>
                           </td>
                           <td>{{(licence?.yearlyData[0]?.usedHours * 	
                            0.0544) | number }} $</td>


                        <td>
                            <span style="background-color: #808080a6;color: white;padding: 5px;border-radius: 3px;" (click)="showDialog(licence)"><span class=" pi pi-eye"></span></span>
                        </td> 
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>

    <p-dialog header="Licensee Usage Details" [(visible)]="visible" [breakpoints]="{ '960px': '75vw' }" [style]="{ width: '50vw' }" [draggable]="false" [resizable]="false">
        <div>
          <p-table [value]="yearlyDetail.yearlyData" [paginator]="true" [rows]="10">
            <ng-template pTemplate="header">
              <tr>
                <th>Year</th>
                <th>Month</th>
                <th>Total Hours</th>
                <th>Used Hours</th>

              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-yearlyDatauser let-columns="columns">
              <tr *ngFor="let monthlyData of yearlyDatauser.monthlyData">
                <td  [attr.rowspan]="yearlyDatauser.monthlyData.length">{{ yearlyDatauser.year }}</td>
                <td>{{ monthlyData.month }}</td>
                <td>{{ monthlyData.totalHours | number }}</td>
                <td>{{ monthlyData.usedHours | number }}</td>
              </tr>
            </ng-template>
            <ng-template pTemplate="footer">
              <tr class="total-row">
                <td colspan="2">Total</td>
                <td>{{ yearlyDatauser?.totalHours | number }}</td>
                <td>{{ yearlyDatauser?.totalUsedHours | number }}</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
        
        <div class="grand-total">
          Grand Total: {{ getTotalGrandTotal()  | number}} hours
        </div>
        <div class="grand-total">
          Grand Total: {{( getTotalGrandTotal() * 	
            0.0544) | number }} USD
        </div>
        
    </p-dialog>