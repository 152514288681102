import {Injectable} from '@angular/core';
import {AppConfig} from '../api/appconfig';

@Injectable()
export class ConfigService {

    config: AppConfig = {
        theme: 'lara-light-indigo',
        dark: false,
        inputStyle: 'outlined',
        ripple: true
    };

    constructor() {
        let themeElement = document.getElementById('theme-css');
        themeElement.setAttribute('href', `assets/theme/${this.config.theme}/theme.css`);
    }

}
