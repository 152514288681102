import {Injectable} from '@angular/core';
import {Auth, UserCredential} from '@angular/fire/auth';
import {GoogleAuthProvider, signInWithPopup} from 'firebase/auth';
import {from, Observable} from 'rxjs';
import {Router} from '@angular/router';
import {environment} from 'src/environments/environment';
import {ApiService} from './api.service';
import {Urls} from "../utils/urls";

@Injectable({
    providedIn: 'root',
})
export class QuerentService {


    constructor(
        private auth: Auth,
        private router: Router,
        private apiService: ApiService
    ) {
    }

    

    async uploadDocument(file,userId) {
        return this.apiService.post(`${Urls.files}?user_id=${userId}`, file, {
        });
    }
    async chatWithDoc(userId,message) {
        return this.apiService.post(`${Urls.qurentChatDocument}/${userId}?message=${message}`, {}, {
        });
    }
    async getAllFiles(folderId){
        return this.apiService.get(`${Urls.files}/all?folderId=`+folderId, {});
    }
    async getFolders(){
        return this.apiService.get(`${Urls.folder}/`, {});
    }
    async chatWithFile(message,documentId,isFolder){
        return this.apiService.post(`${Urls.chat}/chat-single`, {message,documentId,isFolder},{});
    }
    async createFolder(body){
        return this.apiService.post(`${Urls.folder}/`, body);
    }
}
