import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { QuerentService } from '../service/querent.service';
import { LoginService } from '../service/login.service';
import { LoaderService } from '../service/loader.service';
@Component({
  selector: 'app-querent-v2',
  templateUrl: './querent-v2.component.html',
  styleUrls: ['./querent-v2.component.scss']
})
export class QuerentV2Component implements OnInit {
  @Input() multiple: boolean;
  @Input() fileType: string;
  @Input() dragDropEnabled = true;
  @Output() filesChanged: EventEmitter<FileList>;
  @ViewChild('fileInput') inputRef: ElementRef<HTMLInputElement>;

  folders = []
  filesExtension = {
    docs: ['docx', 'docs'],
    pdf: ['pdf'],
    csv: ['csv']
  }
  private patientId!: string;
  messages: Array<any> = [];
  isTyping = false;
  message!: string;
  isSpeaking = true;
  speechData: { voices: any[] } = {
    voices: []
  };
  private speech: any;

  voiceActiveSectionDisabled: boolean = true;
  voiceActiveSectionError: boolean = false;
  voiceActiveSectionSuccess: boolean = false;
  voiceActiveSectionListening: boolean = false;
  voiceText: any;
  voice!: any;
  fontSize = 16
  visibleSidebar2 = false;
  isMicOn = true;
  file: File | any = null;
  fileUploadProgress = 0
  userId = 1
  showUploadDocumentModal = false
  fileUploaed = false;
  allFiles: any;
  folderFiles: any;
  selectedFileForChat;
  showFolderCreateDialog = false;
  selectedFolderId;
  currentSelection = 'FILE';
  newFolderName = '';
  chatMode = false;
  constructor(private qurentService: QuerentService, private loginService: LoginService, private readonly loaderService: LoaderService) {
    this.filesChanged = new EventEmitter();
  }

  ngOnInit(): void {
    const body = document.getElementsByTagName('body')
    body[0].style.overflow = 'hidden'
    const user = this.loginService.getUser()
    this.userId = user?._id?.toString()
    this.getAllFiles();
    this.getFolders()
  }

  async sendConversationMessage(message: string, initConversation: boolean): Promise<void> {
    try {
      this.isTyping = true;
      this.isSpeaking = true;
      this.closeVoiceRecognition();

      const messageBody: any = {
        message: message
      }
      if (this.patientId) {
        messageBody['patient'] = this.patientId;
      }
      if (!initConversation) {
        const doc = {
          type: 'text',
          from: 'user',
          message: message
        }
        console.log(" this.messages", this.messages)
        this.messages.push(doc);
        this.message = '';
        this.messages = JSON.parse(JSON.stringify(this.messages))
        // this.changeDetectorRef.detectChanges()
      }
      this.scrollToBottom()
      const response: any = await this.qurentService.chatWithFile(message, this.selectedFileForChat._id, this.chatMode)
      this.isTyping = false;
      const doc = {
        type: 'text',
        from: 'bot',
        message: response.answer.text
      }
      this.messages.push(doc);
      this.messages = JSON.parse(JSON.stringify(this.messages))
      this.scrollToBottom()
    } catch (err) {
      console.log(err);
    }
  }

  async sendMessage() {
    try {
      if (this.message.trim() !== "") {
        await this.sendConversationMessage(this.message, false);
        this.message = ""
      }
    } catch (err) {
      console.log(err);
    }
  }

  async speakMessage(message: string) {
    this.speech.setLanguage(this.voice.lang);
    this.speech.setVoice(this.voice.name);
    this.speech.speak({
      text: message,
      queue: false,
      listeners: {
        onstart: () => {
        },
        onend: () => {
        },
        onresume: () => {
        }
      }
    }).then((data: any) => {
      this.isSpeaking = false;
      this.startVoiceRecognition()
    }).catch((e: any) => {
      console.error("An error occurred :", e)
    })
  }

  startVoiceRecognition(): void {
    this.voiceActiveSectionDisabled = false;
  }

  closeVoiceRecognition(): void {
    this.voiceActiveSectionDisabled = true;
  }


  increaseFont() {
    this.fontSize++
    document.documentElement.style.setProperty('--chatFontSize', this.fontSize + 'px');
  }

  decreaseFont() {
    this.fontSize--
    document.documentElement.style.setProperty('--chatFontSize', this.fontSize + 'px');
  }

  changeMicType() {
    if (this.isSpeaking) {
      return;
    }
    this.isMicOn = !this.isMicOn
    if (this.isMicOn) {
      this.startVoiceRecognition();
    } else {
      this.closeVoiceRecognition()
    }
  }
  async addFiles(files: FileList): Promise<void> {
    try {
      let formParams = new FormData();
      this.file = {
        name: "Uploading..."
      }
      formParams.append('file', files[0]);
      if (this.selectedFolderId?._id) {
        formParams.append('folderId', this.selectedFolderId?._id)
      }

      await this.qurentService.uploadDocument(formParams, this.userId)
      this.file = files[0]
      this.filesChanged.emit(files);
    } catch (error) {
      console.error("Error in file upload");
      this.file = {
        name: "Error in file upload,Please try again."
      }
    }

  }
  handleFileDrop(event: DragEvent) {
    if (event?.dataTransfer?.files?.length) {
      const files = event.dataTransfer.files;
      this.inputRef.nativeElement.files = files;
      this.addFiles(files);
    }
  }
  selectFileUpload() {
    let element = document.getElementById('fileInput') as HTMLInputElement;
    element.click();
  }
  async getAllFiles(folderId = null) {
    this.loaderService.loader(true)
    try {
      const response: any = await this.qurentService.getAllFiles(folderId);
      if (folderId) {
        this.folderFiles = response.data
      } else {
        this.allFiles = response.data;

      }
      this.loaderService.loader(false)
    } catch (error) {
      this.loaderService.loader(false)

    }
  }
  async getFolders() {
    this.loaderService.loader(true)
    try {
      const response: any = await this.qurentService.getFolders();
      this.folders = response?.data?.folder;
      console.log("folders", this.folders)
      this.loaderService.loader(false)
    } catch (error) {
      this.loaderService.loader(false)

    }
  }
  selectFileToChatEvent(event) {
    this.messages = event?.chatHistory ? event?.chatHistory : [];
    this.scrollToBottom()
  }
  openFolderFileUploadDialog() {

  }
  fileFolderSelection(event) {
    this.selectedFolderId = event;
    this.getAllFiles(event._id)
  }
  switchHeaders(event) {
    this.currentSelection = 'FILE'
    if (!event.index) {
      this.currentSelection = 'FOLDER';
      this.selectedFolderId = null;
    }
  }
  async createFolder() {
    this.loaderService.loader(true)
    try {
      await this.qurentService.createFolder({ name: this.newFolderName });
      this.loaderService.loader(false);
      this.getFolders();
      this.closeDialogs()
    } catch (error) {
      this.loaderService.loader(false);
      this.closeDialogs()
    }
  }
  closeDialogs() {
    this.showFolderCreateDialog = false;
    this.showUploadDocumentModal = false
  }
  scrollToBottom(): void {
    setTimeout(() => {
      var container = document.getElementById("scrollBottom");
      container.scrollTop = container.scrollHeight;
    }, 500)
  }
}