<div class="dashboard">
  <h2 class="title">Enterprises Dashboard</h2>

  <!-- <div class="filters">
    <p-dropdown [options]="timeframes" [(ngModel)]="selectedTimeframe" placeholder="This month"></p-dropdown>
    <p-dropdown [options]="assignees" [(ngModel)]="selectedAssignee" placeholder="All Assignees"></p-dropdown>
    <p-dropdown [options]="projects" [(ngModel)]="selectedProject" placeholder="All Projects"></p-dropdown>
  </div> -->

  <div class="content">
    <div class="stats">
      <div class="stat">
        <div class="stat-content">
          <div>
            <div class="card">
              <div class="card-header">
                <h2 class="card-title">Total no. of meeting</h2>
              </div>
              <div class="card-content">
                <div class="card-number">{{totalMeetings}}</div>
                <img src="assets/images/phone.png" alt="Logo 3" class="card-logo">
              </div>
              <div class="card-footer">
                <div class="stat-change">⬆️ 4% vs Last Month</div>
              </div>
            </div>
            <div class="card">
              <div class="card-header">
                <h2 class="card-title">Total no. of participants</h2>
              </div>
              <div class="card-content">
                <div class="card-number">{{totalParticipants}}</div>
                <img src="assets/images/people.png" alt="Logo 3" class="card-logo">
              </div>
              <div class="card-footer">
                <div class="stat-change">⬆️ 14% vs Last Month</div>
              </div>
            </div>
            <div class="card">
              <div class="card-header">
                <h2 class="card-title">Total hours of meeting</h2>
              </div>
              <div class="card-content">
                <div class="card-number">{{totalMeetingHours}}</div>
                <img src="assets/images/hours_spend.png" alt="Logo 3" class="card-logo">
              </div>
              <div class="card-footer">
                <div class="stat-change">⬆️ 4% vs Last Month</div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <!-- <div class="stat">
        <div class="stat-content">
          <div>
            <div class="stat-value">{{totalMeetingHours}}</div>
            <div class="stat-label">Total hours of meeting</div>
            <div class="stat-change">⬆️ 4% vs Last Month</div>
          </div>
          <div class="stat-icon">
            <i class="pi pi-clock"></i>
          </div>
        </div>
      </div>
      <div class="stat">
        <div class="stat-content">
          <div>
            <div class="stat-value">{{totalParticipants}}</div>
            <div class="stat-label">Total no. of participants</div>
            <div class="stat-change">⬆️ 4% vs Last Month</div>
          </div>
          <div class="stat-icon">
            <i class="pi pi-users"></i>
          </div>
        </div>
      </div> -->
    </div>

    <div class="chart-container">
      <p-tabView>
        <p-tabPanel header="Overall">
          <p-chart type="doughnut" [data]="overallChartData" [options]="chartOptions"></p-chart>
        </p-tabPanel>
        <p-tabPanel header="High Priority">
          <p-chart type="doughnut" [data]="highPriorityChartData" [options]="chartOptions"></p-chart>
        </p-tabPanel>
      </p-tabView>
    </div>
  </div>

  <div class="action-items">
    <p-table [value]="actionItems" [paginator]="true" [rows]="5">
      <ng-template pTemplate="header">
        <tr>
          <th>Title & Description</th>
          <th>Project</th>
          <th>Priority</th>
          <th>Deadline</th>
          <th>Assignee</th>
          <th>Action</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr>
          <td>
            <div class="title-description">
              <div class="title" pTooltip="{{ item.title }}">{{ item.title }}</div>
              <div class="description" *ngIf="item.showMore">{{ item.description }}</div>
              <div class="description" *ngIf="!item.showMore">{{ item?.description | slice:0:100 }}<span
                  *ngIf="item?.description?.length > 100">... </span></div>
              <a (click)="item.showMore = !item.showMore">{{ item.showMore ? 'Show less' : 'Show more' }}</a>
            </div>
          </td>
          <td>{{ item.project }}</td>
          <td>
            <span [ngClass]="{
                'priority-high': item.priority === 'High',
                'priority-medium': item.priority === 'Medium',
                'priority-low': item.priority === 'Low'
              }">{{ item.priority }}</span>
          </td>
          <td>{{ item.deadline }}</td>
          <td>
            <div class="assignee">
              <div class="name">{{ item.assignee }}</div>
              <div class="email">{{ item.email }}</div>
            </div>
          </td>
          <td><a class="link-button" (click)="expand(item)">Expand</a></td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>