<!-- <app-header></app-header>
<p-toast></p-toast>

<div class=" flex align-items-center justify-content-center min-h-screen overflow-hidden bg-accent">
    <div class="grid justify-content-center p-2 lg:p-0" style="min-width: 65%">
        <div class="col-12 xl:col-6  shadow-sm rounded-md bg-white" style="margin-top: 25px;margin-bottom: 25px;padding: 0.3rem;">
            <div class="h-full w-full m-0 py-3 px-4 " >
                <div class="text-center">
                    <h1 class="text-900 font-bold text-4xl  mb-2 color-primary">Sign Up to Meetarise</h1>
                    <span class="text-600 font-medium">To create account, please enter your details below and click on <b>Continue</b>.                    </span>
                </div>
                <form [formGroup]="singupForm">
                    <div class="relative">
                        <div class="p-field">
                            <label class="block text-900  font-medium my-2" for="name">First Name*</label>
                            <input formControlName="firstName" style="width:100%" autofocus id="name" name="name" pInputText required type="text"
                            [ngClass]="{ 'is-invalid': submitted && f['firstName'].errors }" />
                            <div *ngIf="submitted && f['firstName'].errors" class="text-danger">
                                <div *ngIf="f['firstName'].errors['required']">First Name is required</div>
                              </div>
                        </div>
                        <div class="p-field">
                            <label class="block text-900  font-medium my-2" for="lname">Last Name*</label>
                            <input formControlName="lastName" style="width:100%" autofocus id="name" name="lname" pInputText required
                                type="text"
                                [ngClass]="{ 'is-invalid': submitted && f['lastName'].errors }" />
                                <div *ngIf="submitted && f['lastName'].errors" class="text-danger">
                                    <div *ngIf="f['lastName'].errors['required']">Last Name is required</div>
                                  </div>
                        </div>
                        <div class="p-field">
                            <label class="block text-900  font-medium my-2" for="name">Email*</label>
                            <input formControlName="email" style="width:100%" autofocus id="name" name="email" pInputText required
                                type="email"
                                [ngClass]="{ 'is-invalid': submitted && f['email'].errors }" />
                                <div *ngIf="submitted && f['email'].errors" class="text-danger">
                                    <div *ngIf="f['email'].errors['required']">Email is required</div>
                                  </div>
                        </div>
                        <div class="p-field">
                            <label class="block text-900  font-medium my-2" for="phone">Phone*</label>
                            <input formControlName="mobileNumber" style="width:100%" autofocus id="phone" name="phone" pInputText required
                                type="number" numbersOnly maxlength="10"
                                [ngClass]="{ 'is-invalid': submitted && f['mobileNumber'].errors }" />
                                <div *ngIf="submitted && f['mobileNumber'].errors" class="text-danger">
                                    <div *ngIf="f['mobileNumber'].errors['required']">Mobile Number is required</div>
                                  </div>
                        </div>
                        <div class="p-field">
                            <label class="block text-900  font-medium my-2" for="phone">Password*</label>
                            <p-password formControlName="password" [toggleMask]="true">
                                <ng-template pTemplate="header">
                                    <h6>Pick a password</h6>
                                </ng-template>
                                <ng-template pTemplate="footer">
                                    <p-divider></p-divider>
                                    <p class="mt-2">Suggestions</p>
                                    <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                                        <li>At least one lowercase</li>
                                        <li>At least one uppercase</li>
                                        <li>At least one numeric</li>
                                        <li>Minimum 8 characters</li>
                                    </ul>
                                </ng-template>
                            
                            </p-password>
                            <div *ngIf="submitted && f['password'].errors" class="text-danger">
                                <div *ngIf="f['password'].errors['required']">password is required</div>
                              </div>
                        </div>
                        <div class="p-field">
                            <label class="block text-900  font-medium my-2" for="phone">Confirm Password *
                            </label>
                            <p-password formControlName="confirmPassword" [feedback]="false"></p-password>
                            <div  class="text-danger">
                                
                                <div *ngIf="submitted && singupForm.controls['confirmPassword'].hasError('passwordMismatch')">    Passwords do not match.
                                </div>
                              </div>
                        </div>
                        <div class="p-field  md:w-10 mx-auto mt-5" style="width: 35%;">
                            <button [loading]="submitted"  (click)="submit()" pButton pRipple label="Continue"
                                class="w-full p-2 text-xl bg-primary border-0">
                            </button>
                        </div>
                        <h6 style="margin-top: 13px;">
                            Or Continue With
                        </h6>
                        <div class="p-field mt-5">
                            <div class="w-full md:w-10 mx-auto">
                                <button (click)="signIn()" pButton pRipple label="Google"
                                    class="login-with-google-btn w-full text-xl bg-white border-radius border-t">
                                    <i class="pi pi-google"></i>
                                </button>
                            </div>
                            <div class="text-sm text-mute d-flex align-items-center justify-content-center p-2 ">
                                Have an account?
                                &nbsp;
                                <span routerLink="/login"
                                    class="btn-link --primary-color transition-all color-primary">Sign-In</span>
                            </div>
                            <div class="text-sm text-mute d-flex align-items-center justify-content-center p-2 ">
                                By using Meetarise you agree to the <a href="https://meetarise.com/privacy-policy" class="btn-link --primary-color transition-all color-primary"> Terms of Servicep </a>
                                &nbsp; and    &nbsp; <a href="https://meetarise.com/privacy-policy"class="btn-link --primary-color transition-all color-primary"> Privacy Policy</a>
            
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div> -->

<div class="container">
    <div class="left-section">
      <img src="/assets/images/recapi-logo.png" alt="Recapi.Ai Logo" class="logo" />
      <img src="/assets/images/meetarise_overview.png" alt="Dashboard Image" class="dashboard-image" />
    </div>
    <div class="right-section">
      <div class="form-container">
        <h2>Create account</h2>
        <form [formGroup]="signupForm" (ngSubmit)="onSubmit()">
          <div *ngFor="let field of formFields" class="form-group">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <i [ngClass]="field.icon"></i>
              </span>
              <input 
                pInputText 
                [placeholder]="field.placeholder" 
                formControlName="{{field.controlName}}" 
                [type]="field.type" 
              />
            </div>
            <div *ngIf="signupForm.get(field.controlName).invalid && signupForm.get(field.controlName).touched" class="error">
              {{ field.errorMessage }}
            </div>
          </div>
          <button pButton (click)="onSubmit()" type="submit" label="Continue" class="signup-button p-button-primary"></button>
          <div class="d-flex justify-content-center">
            <button type="button" class="google-login-button"  (click)="signIn()" >
                <img src="https://developers.google.com/static/identity/images/g-logo.png" alt="Google Icon"> Google
                Login
            </button>
        </div>          <p class="login-link">Already have an account? <a href="#">Login</a></p>
          <p class="terms">By using Recapi you agree to the <a href="#">Terms of service</a> and <a href="#">Privacy policy</a></p>
        </form>
      </div>
    </div>
  </div>
    