import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import {CalendarOptions, DateSelectArg, EventClickArg} from '@fullcalendar/core'; // useful for typechecking
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import {Urls} from "../utils/urls";
import {ApiService} from "../service/api.service";
import {UtilsService} from "../service/utils.service"
import {MessageService} from "primeng/api";
import {HttpParams} from "@angular/common/http";

@Component({
  selector: 'app-meeting',
  templateUrl: './meeting.component.html',
  styleUrls: ['./meeting.component.scss']
})
export class MeetingComponent implements OnInit {
    visible: boolean = false;
    isEdit=false
    showLoader = false;
    meetingData={
        name:"",
        link:"",
        mom:"",
        status:"",
        createdAt:""
    }
    calendarOptions: CalendarOptions = {
        editable: true,
        initialView: 'dayGridMonth',
        headerToolbar: {
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
        },
        plugins: [
            interactionPlugin,
            dayGridPlugin,
            timeGridPlugin,
            listPlugin,
        ],
        weekends: true,
        selectable: true,
        select: this.handleDateSelect.bind(this),
        eventClick: this.handleEventClick.bind(this),
        eventAdd:this.handleDateSelect.bind(this)
    };
    meetings=[]
  constructor(private readonly apiService: ApiService, private messageService: MessageService,
   private utilsService: UtilsService) {}

  @ViewChild('moms', { static: false }) momPDf: ElementRef;
  ngOnInit(): void {
        this.fetchMeeting()
  }

  async fetchMeeting(){
      const params = {
          limit: 50,
          page: 1,
      }
      this.showLoader=true;
      const res: any = await this.apiService.get(Urls.meeting, {params: params as unknown as HttpParams})
      this.showLoader=false
      this.meetings = res.data.docs.map((event)=>{
          event.title=event.name
          event.date=event.createdAt
          event.id=event._id.toString()
          return event
      });
    }
    handleDateSelect(selectInfo: any) {
        this.resetMeetingData()
        this.meetingData.createdAt=selectInfo.start
        this.visible=true
        this.isEdit=false
        // const title = prompt('Please enter a new title for your event');
        // const calendarApi = selectInfo.view.calendar;
        //
        // calendarApi.unselect(); // clear date selection

    }
    resetMeetingData(){
        this.meetingData={
            name:"",
            link:"",
            mom:"",
            status:"",
            createdAt:""
        }
    }
    handleEventClick(clickInfo: EventClickArg) {
        this.meetingData=this.meetings.find(e=>e._id.toString()===clickInfo.event._def.publicId)
        this.visible=true
        this.isEdit=true
    }
    closeDialog() {
        this.visible = false;
    }
    async saveMeeting(){
        this.showLoader = true;
        if (this.isEdit) {
            // await this.apiService.put(`${Urls.meeting}/${this.editId}`, {...this.form.value})
        } else {
            delete this.meetingData.status;
            delete this.meetingData.mom;
            await this.apiService.post(Urls.meeting, {...this.meetingData})
        }
        this.showLoader = false;
        this.visible=false
        this.messageService.add({
            severity: 'success',
            summary: 'Successful',
            detail: this.isEdit ? 'Meeting Updated' : 'Meeting Created.',
            life: 3000,
        });
        this.fetchMeeting()
    } catch
    (error) {
        this.showLoader = false;
        console.error(error);
        this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: this.isEdit ? 'Unable to update Meeting.' : 'Unable to create Meeting.',
            life: 3000,
        });
    }

    onClickDownloadPdf(element) {
       if (this.meetingData.mom != undefined && this.meetingData.mom.length > 0) {
           //this.utilsService.downloadPDF({"Testing": "Testing"}, this.name);
           this.utilsService.downloadAsPDF(this.momPDf, this.meetingData.name);
       }
    }
}
