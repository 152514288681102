import { Component, OnInit } from '@angular/core';
// import { ConfigService } from '../../service/app.config.service';
import { LoginService } from 'src/app/service/login.service';
import { Router } from '@angular/router';
import { map } from 'rxjs';
import { MessageService } from 'primeng/api';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgOtpInputConfig } from 'ng-otp-input';
import { LoaderService } from 'src/app/service/loader.service';

@Component({
    selector: 'app-otp-verification',
    templateUrl: './otp-verification.component.html',
    styleUrls: ['./otp-verification.component.scss'],
})
export class OTPVerificationComponent implements OnInit {
    seconds = 60;
    email = ''
    verifyOtpRequestSent = false
    code = ''
    config: NgOtpInputConfig = {
        allowNumbersOnly: true,
        length: 6,
    };
    constructor(
        // public configService: ConfigService,
        private loginService: LoginService,
        private router: Router,
        private messageService: MessageService,
        private loaderService: LoaderService

    ) {
    }

    ngOnInit(): void {
        if (!history.state.email) {
            this.router.navigate(['/'])
        } else {
            this.email = history.state.email
        }
        this.startTimmer(60)

    }
    onOtpChange(otp) {
        this.code = otp;
        if (this.code.length === 6 && !this.verifyOtpRequestSent) {
            this.verifyOtpRequestSent = true
            this.verifyOTP()
        }
    }
    async verifyOTP() {
        try {
            this.loaderService.loader(true);
            const response: any = await this.loginService.verifyOTP({ email: this.email, code: this.code })
            this.messageService.add({
                severity: 'sucess',
                summary: 'Login',
                detail: response.message
            })
            this.loginService.setSignInDetails({ user: response.data });
            this.loginService.setToken(response.data);
            this.loaderService.loader(false);
            this.router.navigate(['/']);
        } catch (error) {
            this.verifyOtpRequestSent = false

            console.error("ERROR1111", error)
            this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: error?.error?.description ? error?.error?.description : "Server Error",
            });
            this.loaderService.loader(false);
        }
    }
    startTimmer(seconds) {
        this.seconds = seconds;
        const myInterval = setInterval(() => {
            if (this.seconds > 0) {
                this.seconds = this.seconds - 1
            } else {
                this.seconds = 0
                // this.resendOtp()
                clearInterval(myInterval)
            }
        }, 1000)
    }
    async resendOtp() {
        try {

            const response: any = await this.loginService.resendOtp({ email:this.email ,type:this.loginService.OTP_TYPE.SIGNUPOTP,})
            this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: response.message
            });
            this.startTimmer(60)
            this.loaderService.loader(false);
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: error?.error?.data[0] ? error?.error?.data[0].msg : "Server Error",
            });
            this.loaderService.loader(false);
        }
    }
}