import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ApiService } from 'src/app/service/api.service';
import { Urls } from 'src/app/utils/urls';

@Component({
  selector: 'app-integration',
  templateUrl: './integration.component.html',
  styleUrls: ['./integration.component.scss']
})
export class IntegrationComponent implements OnInit {
  visible = false;
  isJiraEnable=false
  showLoader=false
  isEdit=false
  constructor(private readonly apiService: ApiService, private messageService: MessageService) { }
  integrationData={
    JIRA_USERNAME:"",
    JIRA_API_TOKEN:"",
    action:"ADD",
    PROJECT_KEY:"",
    JIRA_BASE_URL:"",
    type:"JIRA",
    _id:undefined
  }
  ngOnInit(): void {
    this.fetchIntegration()
  }
  closeDialog(){
    this.visible=false
  }
  eventHandler(event){
    if(event){
      this.visible = true
    }else{
      this.saveintegrationData()
    }    
    
  }

  async saveintegrationData(){
    this.showLoader = true;
    if (!this.isJiraEnable) {
      this.integrationData.action ="REMOVE"
        // await this.apiService.put(`${Urls.meeting}/${this.editId}`, {...this.form.value})
    }
    await this.apiService.post(Urls.client, {...this.integrationData})

    this.showLoader = false;
    this.visible=false
    this.messageService.add({
        severity: 'success',
        summary: 'Successful',
        detail: this.isEdit ? 'Integration Updated' : 'Integration Created.',
        life: 3000,
    });
    this.fetchIntegration()
} catch
(error) {
    this.showLoader = false;
    console.error(error);
    this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: this.isEdit ? 'Unable to update Meeting.' : 'Unable to create Meeting.',
        life: 3000,
    });
}
  async fetchIntegration(){
    const params = {
        limit: 50,
        page: 1,
    }
    this.showLoader=true;
    const res: any = await this.apiService.get(Urls.client, {params: params as unknown as HttpParams})
    this.showLoader=false
    if(res.data.docs[0].integration.length){
      this.isJiraEnable = true
      this.integrationData=res.data.docs[0].integration[0]
      this.integrationData.type = 'JIRA',
      this.integrationData.action = 'ADD'
    }
    // this.integrationData = res.data.docs.map((event)=>{
    //     event.title=event.name
    //     event.date=event.createdAt
    //     event.id=event._id.toString()
    //     return event
    // });
  }
}
