<div class="grid">
    <div class="col-12">
        <div class="card p-0">
            <p-toolbar>

            </p-toolbar>
            <div class="container">
                <h2>Previous meetings</h2>
                <div class="search-bar">
                    <div class="search-input-wrapper">
                        <input type="text" pInputText placeholder="Search meeting" [(ngModel)]="searchValue"
                            (ngModelChange)="onSearch()" />
                        <i class="pi pi-search search-icon"></i>
                    </div>
                    <p-calendar [(ngModel)]="dateRange" selectionMode="range" dateFormat="dd/mm/yy"
                        placeholder="1 Feb <> 30 March"></p-calendar>
                </div>
                <p-table [value]="connections" [paginator]="true" [rows]="5">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Meeting date & time</th>
                            <th>Subject</th>
                            <th>Status</th>
                            <th>Meeting notes</th>
                            <th>Action items to Jira</th>
                            <th>Report</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-meeting>
                        <tr>
                            <td>{{formatDate(meeting.createdAt)}}<br>{{meeting.time}}</td>
                            <td>
                                <a href="#" class="subject-link" title="{{meeting.subject}}">{{meeting.name}}</a>
                            </td>
                            <td
                                [ngClass]="{'status-inprogress': meeting.status === 'in_Progress', 'status-completed': meeting.status === 'completed'}">
                                {{meeting.status}}
                            </td>
                            <td><a (click)="viewNotes(meeting)" class="action-link">View</a></td>
                            <td><a href="#" pTooltip="Comming Soon" (click)="pushToJira(meeting)"
                                    class="action-link">Push</a></td>
                            <td><a href="#" pTooltip="Comming Soon" (click)="expandReport(meeting)"
                                    class="action-link">Expand</a></td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>

        </div>
        <p-dialog [(visible)]="displayConnectionSidebar" [breakpoints]="{ '960px': '75vw' }" [draggable]="false"
            [modal]="true" position="right" [style]="{width: '60vw'}" [header]="meetingData.name">
            <app-connection [meetingData]="meetingData" [link]="meetingData.link" [name]="meetingData.name"
                [action]="meetingData.action" [mom]="meetingData.mom" (closeSidebar)="onClose($event)"></app-connection>
        </p-dialog>