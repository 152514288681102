import {Component, OnInit} from '@angular/core';
import {Urls} from "../../utils/urls";
import {HttpParams} from "@angular/common/http";
import {ApiService} from "../../service/api.service";
import {LoaderService} from "../../service/loader.service";
import {UtilsService} from "../../service/utils.service";

@Component({
    selector: 'app-user-list',
    templateUrl: './user-list.component.html',
    styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {
    users: any = []
    totalDocs: number = 0;

    constructor(private readonly apiService: ApiService, private loaderService: LoaderService, readonly utilsService: UtilsService) {
    }

    ngOnInit(): void {
        
    }

    async getUsers(event) {
        const params = {
            limit: event.rows,
            page: (event.first / event.rows) + 1,
            sortOrder: event.sortOrder,
            sortField: event.sortField,
            filter: event.globalFilter ? event.globalFilter : ''
        }
        this.loaderService.loader(true);
        const res: any = await this.apiService.get(Urls.users, {params: params as unknown as HttpParams})
        this.loaderService.loader(false);
        this.users = res.data.docs;
        this.totalDocs = res.data.totalDocs;
    }
}
