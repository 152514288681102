<app-header></app-header>
<p-toast></p-toast>
<div class="flex align-items-center justify-content-center min-h-screen overflow-hidden bg-accent">
    <div class="grid justify-content-center p-2 lg:p-0 " style="min-width: 60%">
        <div class="col-12 xl:col-6 bg-white shadow-sm rounded-md" style="
        padding: 0.3rem;
        /* background-color: aliceblue; */
        /* background: linear-gradient(
            180deg,
            var(--primary-color) 10%,
            rgba(55, 163, 251, 0.196) 30%
        ); */
    ">
            <div class="h-full w-full m-0 py-3 px-4">

                <div class="text-center">
                    <h1 class="text-900 font-bold text-4xl lg:text-5xl mb-2 color-primary">Reset Password?</h1>
                    <span class="text-600 font-medium"> {{!isOtpSent? 'Please type your mail address to reset your
                        password.':"We have sent you an email with 6-digit verification code on " + email }}
                    </span>
                </div>
                <form [formGroup]="forgotPassword">
                    <div class="p-field" *ngIf="!isOtpSent">
                        <label class="block text-900  font-medium my-2" for="email">Email*</label>
                        <input sttyle="width:100%" autofocus id="email" name="email" pInputText type="text"
                            formControlName="email" />
                        <div
                            *ngIf="forgotPassword.controls['email'].invalid && forgotPassword.controls['email'].touched ">
                            <div *ngIf="forgotPassword.get('email')?.errors?.['required']"
                                class="alert alert-danger text-danger">
                                Email is required
                            </div>
                            <div *ngIf="forgotPassword.get('email')?.errors?.['email']"
                                class="alert alert-danger text-danger">
                                Please enter valid email address.
                            </div>
                        </div>

                        <div class="w-full md:w-10 mx-auto mt-5">
                            <button [loading]="isLoader" (click)="submit()" pButton pRipple label="Continue"
                                class="w-full p-2 text-xl bg-primary border-0">
                            </button>
                        </div>
                    </div>


                    <div *ngIf="isOtpSent">
                        <div class="p-field mt-5">
                            <label class="block text-900  font-medium my-2" for="phone">OTP*</label>
                            <div class="d-flex justify-content-center align-items-center">
                                <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="config"></ng-otp-input>
                               
                            </div>
                            <!-- <div
                            *ngIf="!code && code.length !=6 "
                                class="alert alert-danger text-danger">
                                Please enter 6 digit OTP
                        </div> -->
                        </div>
                        <div class="p-field">
                            <label class="block text-900  font-medium my-2" for="phone">Password*</label>
                            <p-password autofocus id="password" name="password" required formControlName="password"
                                type="password" [toggleMask]="true">

                            </p-password>
                            <div
                                *ngIf="forgotPassword.controls['password'].invalid && forgotPassword.controls['password'].touched ">
                                <div *ngIf="forgotPassword.get('password')?.errors?.['required']"
                                    class="alert alert-danger text-danger">
                                    password is required
                                </div>
                            </div>
                        </div>
                        <div class="p-field">
                            <label class="block text-900  font-medium my-2" for="phone">Confirm password *</label>
                            <p-password autofocus id="Confirmpassword" name="Confirmpassword" [toggleMask]="false"
                                required formControlName="confirmPassword" type="password">

                            </p-password>
                            <div
                            *ngIf="forgotPassword.controls['confirmPassword'].invalid && forgotPassword.controls['confirmPassword'].touched ">
                            <div *ngIf="forgotPassword.get('confirmPassword')?.errors?.['required']"
                                class="alert alert-danger text-danger">
                                Confirm password is required
                            </div>
                        </div>
                        </div>
                        <div class="w-full md:w-10 mx-auto mt-5">
                            <button (click)="resetpassword()" pButton pRipple label="Reset Password"
                                class="w-full p-2 text-xl bg-primary border-0">
                            </button>
                        </div>
                        <div *ngIf="isOtpSent">
                            <div class="d-flex justify-content-center">
                                <p class="my-2 mt-3 text-mute">
                                    Did not receive the code?
                                    <span *ngIf="seconds===0" class="text-primary cursor-pointer inline"
                                        (click)="resendOtp()">
                                        Resend OTP
                                    </span>
                                </p>

                            </div>
                            <div class=" d-flex justify-content-center">
                                <p class="px-4 py-1 mt-3 rounded-lg bg-gray text-mute">{{seconds?(seconds +' Sec'):"OTP
                                    Expired!

                                    "}}</p>
                            </div>
                        </div>

                    </div>

                </form>

            </div>
        </div>
    </div>
</div>