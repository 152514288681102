<app-loader [displayLoader]="showLoader"></app-loader>

<div class="relative pl-4">
    <!--    <form class="p-fluid" [formGroup]="form">-->
    <div *ngIf="editId" class="p-field my-2">
        <p-selectButton
            [options]="statusOptions"
            formControlName="status"
            ngModel
            optionLabel="label"
            optionValue="value"
            required
        ></p-selectButton>
    </div>
    <!-- <div class="p-field">
        <label class="block text-900  font-medium my-2" for="name">Name</label>
        <input [(ngModel)]="name" autofocus
               id="name"
               name="name"
               pInputText
               required
               type="text"/>
    </div> -->
    <!-- <div class="p-field">
        <label for="type" class="block text-900 font-medium my-2">Type</label>
        <p-dropdown (onChange)="changedType($event)" [options]="connectionTypes"
                    formControlName="type"
                    id="type"></p-dropdown>
    </div> -->
    <!-- <div class="p-field">
        <label class="block text-900 font-medium my-2" for="link">Meet Link</label>
        <input [(ngModel)]="link" autofocus
               id="link"
               pInputText
               required
               type="text"/>
    </div> -->
        <div class="p-field"  id="export">
            <div pTooltip="Download" style="float: right; padding-left: 10px;" (click)="onClickDownloadPdf()">
               
                <i  style="cursor:pointer;"class="fa fa-download">
                    <span>Download</span>
                </i>
            </div>
            <br />
            <p-accordion [multiple]="true" [activeIndex]="[0,1]" class="block text-900 font-medium my-2">
                    <p-accordionTab header="Minutes of Meeting" class="block text-900 font-medium my-2" [selected]="true">
                        <div #moms>
                            <div class="block text-900 font-medium">
                                <!-- {{mom}} -->
                                <!-- <ul class="card action-item text-900 font-medium" > -->
                                    <ul>
                                        <li *ngFor="let item of mom?.keyPoints">{{item}}</li>
                                     
                                      </ul>
                                <!-- </ul> -->
                            </div>
                            <!-- <textarea disabled pInputTextarea rows="10" cols="68" class="block text-900 font-medium my-2">{{mom}}</textarea> -->
                        </div>
                    </p-accordionTab>
                    <p-accordionTab  header="Meeting Action Points" class="block text-900 font-medium my-2" [selected]=true>
                        <div *ngIf="!isJsonString(action)">
                            <div class="card action-item text-900 font-medium" *ngFor="let item of action">
                                <b>Title:-</b> {{item.title}} <br>
                                <b>Priority:-</b> {{item.priority}} <br>
                                <b>Due Date:-</b> {{item.duedate}} <br>
                                <b>Description</b> {{item.description}} <br>
                            </div>
                        </div>
                        <div *ngIf="isJsonString(action)">
                        <div #actionItems>
                            <textarea id="actionItems" disabled pInputTextarea rows="13" cols="68" class="block text-900 font-medium my-2">
                                {{action}}
                            </textarea>
                        </div>
                        </div>
                    </p-accordionTab>
            </p-accordion>
        </div>

    <!--        <div formGroupName="ghl" *ngIf="this.form.value.type === 'Go High Level'">-->
    <!--            <div class="p-field">-->
    <!--                <label for="locationId" class="block text-900 font-medium my-2">Meet Link</label>-->
    <!--                <input id="locationId" type="text"-->
    <!--                       pInputText-->
    <!--                       autofocus-->
    <!--                       required-->
    <!--                       ngModel/>-->
    <!--            </div>-->
    <!--            <div class="p-field">-->
    <!--                <label for="apiKey" class="block text-900 font-medium my-2">API Key</label>-->
    <!--                <input id="apiKey" type="text"-->
    <!--                       pInputText-->
    <!--                       autofocus-->
    <!--                       required-->
    <!--                       ngModel/>-->
    <!--            </div>-->
    <!--        </div>-->
    <!-- <div class="p-field">
        <label for="dfkey" class="block text-900 font-medium my-2">Dialogflow Service Account Key</label>
        <p-fileUpload id="dfkey"
                      [customUpload]="true"
                      fileLimit="5"
                      mode="basic"
                      name="file"
                      accept=".json"
                      (onSelect)="onSelected($event)"
                      maxFileSize="1000000"
                      chooseLabel="Upload Key"
                      styleClass="p-button-secondary block"></p-fileUpload>
    </div> -->
    <!--    </form>-->
<!--    <div class="right">-->
<!--        <button (click)="saveConnection()" class="p-button-success right mt-2" label="Save"-->

<!--                pButton-->
<!--                pRipple></button>-->
<!--    </div>-->
</div>
