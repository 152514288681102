import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgOtpInputConfig } from 'ng-otp-input';
import { MessageService } from 'primeng/api';
import { ApiService } from 'src/app/service/api.service';
import { LoaderService } from 'src/app/service/loader.service';
import { LoginService } from 'src/app/service/login.service';


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  constructor(public apiService: LoginService, public messageService: MessageService, private loaderService: LoaderService,        private router: Router,

  ) { }
  seconds = 60;
  forgotPassword: FormGroup = new FormGroup({
    'email': new FormControl('', [Validators.required, Validators.email]),
    'confirmPassword': new FormControl('', Validators.required),
    'password': new FormControl('', Validators.required),


  });
  code: any='';
  confirm: any = '';
  config: NgOtpInputConfig = {
    allowNumbersOnly: true,
    length: 6,
  };
  isLoader = false;
  isOtpSent = false;
  email = ''
  response: any;
  formData: any;
  ngOnInit(): void {
  }


  onOtpChange(otp) {
    this.code = otp;
    
  }
  async submit() {

    try {
      if (this.forgotPassword.controls.email.valid) {

        this.formData = {
          email: this.forgotPassword.get('email').value,
        }
        this.email = this.forgotPassword.get('email').value
        this.isLoader = true;
        this.loaderService.loader(true);
        const response:any = await this.apiService.forgetpassword(this.formData);
        this.loaderService.loader(false);
        this.isOtpSent = true
        this.startTimmer(this.seconds)
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: response.message
      });
      }else{
        this.forgotPassword.markAllAsTouched();
      }
    } catch (error) {
      this.isLoader = false;
      console.error("ERROR1111", error.error)
      const message = error?.error?.message ? error?.error?.message : (error?.error?.data[0] ? error?.error?.data[0].msg : "Server Error")
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: message
      });
      this.loaderService.loader(false);
    }


  }


  async resetpassword() {
    try {
      if(this.forgotPassword.valid){
        const validationResponse =  this.checkValidateForResetPassword()
        if(validationResponse.isError){
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: validationResponse.message,
  
          });
          return;
        }
          this.isLoader = true;
          this.loaderService.loader(true);
          this.formData = {
            email: this.forgotPassword.get('email').value,
            password: this.forgotPassword.get('password').value,
            code: this.code
          };
          const response:any = await this.apiService.resetPassword(this.formData);
          this.loaderService.loader(false);
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: response.message
        });
        setTimeout(()=>{
          this.router.navigate(['/login']);
        },500)        
      }else{
        this.forgotPassword.markAllAsTouched();
      }

    } catch (error) {
      this.isLoader = false;
      const message = error?.error?.message ? error?.error?.message : (error?.error?.data[0] ? error?.error?.data[0].msg : "Server Error")
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: message
      });
      this.loaderService.loader(false);
    }

  }
  checkValidateForResetPassword(){
    let isError =  false;
    let message = ''
    if(!this.code || this.code.length !==6){
      isError = true;
      message = 'Please enter 6 digit OTP'
    }else if(this.forgotPassword.value.password != this.forgotPassword.value.confirmPassword){
      isError = true;
      message = 'Password and Confirm Password does not match'
    }
    return {isError,message}
  }
  startTimmer(seconds) {
    this.seconds = seconds;
    const myInterval = setInterval(() => {
      if (this.seconds > 0) {
        this.seconds = this.seconds - 1
      } else {
        this.seconds = 0
        // this.resendOtp()
        clearInterval(myInterval)
      }
    }, 1000)
  } 
    async resendOtp() {
      try {

          const response: any = await this.apiService.resendOtp({
            email: this.email, type: this.apiService.OTP_TYPE.FORGETPASSOTP,
          })
          this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: response.message
          });
          this.startTimmer(60)
          this.loaderService.loader(false);
      } catch (error) {
          this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: error?.error?.data[0] ? error?.error?.data[0].msg : "Server Error",
          });
          this.loaderService.loader(false);
      }
  }
}