<!-- <app-header></app-header> -->
<p-toast></p-toast>

<!-- <div class=" flex align-items-center justify-content-center min-h-screen overflow-hidden"
   >
    <div class="grid justify-content-center p-2 lg:p-0" style="min-width: 60%">
        <div class="col-12 xl:col-6" style="margin-top: 25px;margin-bottom: 25px;padding: 0.3rem;">
            <div class="h-full w-full m-0 py-2 px-4 " style=" background-color:white;">
                <div class="text-center mb-5">
                    <h3 class="text-900 font-bold mb-2 color-primary">Enter Your OTP</h3>
                    <span class="text-600 font-medium">We have sent you an Email/SMS with 6-digit verification code on
                        <b>{{email}}</b>
                    </span>
                </div>
                <div class="d-flex justify-content-center align-items-center">
                    <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="config"></ng-otp-input>

                </div>
                <div class="d-flex justify-content-center">
                    <p class="my-2 mt-3 text-mute">
                        Did not receive the code?
                        <span *ngIf="seconds===0"class="text-primary cursor-pointer inline" (click)="resendOtp()">
                            Resend OTP
                        </span>
                    </p>

                </div>
                <div class=" d-flex justify-content-center">
                    <p class="px-4 py-1 mt-3 rounded-lg bg-gray text-mute">{{seconds?(seconds +' Sec'):"OTP Expired!

                        "}}</p>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div class="container">
    <div class="left-section">
      <img src="/assets/images/recapi-logo.png" alt="Recapi.Ai Logo" class="logo" />
      <img src="/assets/images/meetarise_overview.png" alt="Dashboard Image" class="dashboard-image" />
    </div>
    <div class="right-section">
      <div class="form-container">
        <h2>Enter Your OTP</h2>
        <span class="text-600 font-medium">We have sent you an Email/SMS with 6-digit verification code on
            <b>{{email}}</b>
        </span>
        <div class="d-flex justify-content-center align-items-center">
            <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="config"></ng-otp-input>

        </div>
        <div class="d-flex justify-content-center">
            <div>
                <p class="my-2 text-mute">
                    Did not receive the code?
                    <span *ngIf="seconds===0"class="text-primary cursor-pointer inline" (click)="resendOtp()">
                        Resend OTP
                    </span>
                    
                </p>
            </div>
          
          
        </div>
        <div class=" d-flex justify-content-center">
            <p class="px-4 py-1 mt-3 rounded-lg bg-gray text-mute">{{seconds?(seconds +' Sec'):"OTP Expired!

                "}}</p>
        </div>
        <!-- <form [formGroup]="signupForm" (ngSubmit)="onSubmit()">
          <div *ngFor="let field of formFields" class="form-group">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <i [ngClass]="field.icon"></i>
              </span>
              <input 
                pInputText 
                [placeholder]="field.placeholder" 
                formControlName="{{field.controlName}}" 
                [type]="field.type" 
              />
            </div>
            <div *ngIf="signupForm.get(field.controlName).invalid && signupForm.get(field.controlName).touched" class="error">
              {{ field.errorMessage }}
            </div>
          </div>
          <button pButton (click)="onSubmit()" type="submit" label="Continue" class="signup-button p-button-primary"></button>
          <div class="d-flex justify-content-center">
            <button type="button" class="google-login-button"  (click)="signIn()" >
                <img src="https://developers.google.com/static/identity/images/g-logo.png" alt="Google Icon"> Google
                Login
            </button>
        </div>          <p class="login-link">Already have an account? <a href="#">Login</a></p>
          <p class="terms">By using Meetarise you agree to the <a href="#">Terms of service</a> and <a href="#">Privacy policy</a></p>
        </form> -->
      </div>
    </div>
  </div>
    