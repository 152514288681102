import {environment} from '../../environments/environment';

export class Urls {
  static baseUrl = `${environment.apiUrl}api/`;
  static documentAIUrl = `${environment.documentAI}`;

  // Auth
  static login = `${Urls.baseUrl}user/login`;
  static logout = `${Urls.baseUrl}user/logout`;

  static connection = `${Urls.baseUrl}connection`;
  static users = `${Urls.baseUrl}user/list`;
  static analytics = `${Urls.baseUrl}analytics`;

  static meeting = `${Urls.baseUrl}meeting`;
  static client = `${Urls.baseUrl}client`;

  static licence = `${Urls.baseUrl}licence`;
  static licenceAll = `${Urls.baseUrl}licence/all`;
  static analyticsV2 = `${Urls.baseUrl}analytics`;
  static dashboardEvent = `${Urls.baseUrl}event/events-by-date`;

  static authLogin = `${Urls.baseUrl}auth/login`;
  static authSignUp = `${Urls.baseUrl}auth/register`;
  static forgetpassword = `${Urls.baseUrl}auth/forget-password`;
  static resetPassword = `${Urls.baseUrl}auth/reset-password`;

  static verifyOtp = `${Urls.baseUrl}auth/verify-otp`;
  static resendOtp = `${Urls.baseUrl}auth/re-send-otp`;
  static qurentUploadDocument = `${Urls.documentAIUrl}/upload`;
  static qurentChatDocument = `${Urls.documentAIUrl}/chat`;
  static files = `${Urls.baseUrl}file`;
  static chat = `${Urls.baseUrl}chat`;
  static folder = `${Urls.baseUrl}folder`;

}
