<div class="grid">
    <div class="col-12">
        <p-calendar [(ngModel)]="selectedRange" selectionMode="range" (onSelect)="getAnalytics()"></p-calendar>
    </div>
    <div class="col-12 lg:col-4 xl:col-4">
        <div class="card mb-0">
            <div class="flex justify-content-between mb-3">
                <div>
                    <span class="block text-500 font-medium mb-3">Total Request</span>
                    <div class="text-900 font-medium text-xl">{{totalRequests}}</div>
                </div>
                <div class="flex align-items-center justify-content-center bg-blue-100 border-round"
                     [ngStyle]="{width: '2.5rem', height: '2.5rem'}">
                    <i class="pi pi-sort-alt text-blue-500 text-xl"></i>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 lg:col-4 xl:col-4">
        <div class="card mb-0">
            <div class="flex justify-content-between mb-3">
                <div>
                    <span class="block text-500 font-medium mb-3">Total Success</span>
                    <div class="text-900 font-medium text-xl">{{totalHandled}}</div>
                </div>
                <div class="flex align-items-center justify-content-center bg-green-100 border-round"
                     [ngStyle]="{width: '2.5rem', height: '2.5rem'}">
                    <i class="pi pi-check-circle text-green-500 text-xl"></i>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 lg:col-4 xl:col-4">
        <div class="card mb-0">
            <div class="flex justify-content-between mb-3">
                <div>
                    <span class="block text-500 font-medium mb-3">Total Fallback</span>
                    <div class="text-900 font-medium text-xl">{{totalFallback}}</div>
                </div>
                <div class="flex align-items-center justify-content-center bg-yellow-100 border-round"
                     [ngStyle]="{width: '2.5rem', height: '2.5rem'}">
                    <i class="pi pi-times-circle text-yellow-500 text-xl"></i>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12 xl:col-12">
        <div class="card mb-0">
            <h5>Request by day</h5>
            <p-chart type="line" [data]="chartData" [options]="chartOptions"></p-chart>
        </div>
    </div>

    <div class="col-12 lg:col-6 xl:col-4">
        <div class="card">
            <h5>Top 10 Intent</h5>
            <div class="no-data-found" *ngIf="topIntent.length===0">
                No data found
            </div>
            <div class="main" *ngFor="let message of topIntent">
                <div class="text">{{message.name}}</div>
                <div class="value">{{message.value}}</div>
            </div>
        </div>
    </div>
    <div class="col-12 lg:col-6 xl:col-4">
        <div class="card">
            <h5>Top 10 Message</h5>
            <div class="no-data-found" *ngIf="topIntent.length===0">
                No data found
            </div>
            <div class="main" *ngFor="let message of topMessage">
                <div class="text">{{message.name}}</div>
                <div class="value">{{message.value}}</div>
            </div>
        </div>
    </div>
    <div class="col-12 lg:col-6 xl:col-4">
        <div class="card">
            <h5>Top 10 Fallback</h5>
            <div class="no-data-found" *ngIf="topIntent.length===0">
                No data found
            </div>
            <div class="main" *ngFor="let message of topFallback">
                <div class="text">{{message.name}}</div>
                <div class="value">{{message.value}}</div>
            </div>
        </div>
    </div>
</div>

