
    <div class="dashboard">
        <div class="analytics">
            <div class="d-flex">
                <div class="card-container">
                    <div class="card">
                        <div class="card-header">
                            <h2 class="card-title">Open action items</h2>
                        </div>
                        <div class="card-content">
                            <div class="card-number">{{actionItemsCount.open}}</div>
                            <img src="assets/images/open_action.png" alt="Logo 1" class="card-logo">
                        </div>
                        <div class="card-footer">
                            <p class="card-text">Some small text here</p>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header">
                            <h2 class="card-title">Closed action items</h2>
                        </div>
                        <div class="card-content">
                            <div class="card-number">{{actionItemsCount.closed}}</div>
                            <img src="assets/images/close_action.png" alt="Logo 2" class="card-logo">
                        </div>
                        <div class="card-footer">
                            <p class="card-text">Vs Last Month</p>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header">
                            <h2 class="card-title">Hours spent</h2>
                        </div>
                        <div class="card-content">
                            <div class="card-number">{{totalMeetingHours}}</div>
                            <img src="assets/images/hours_spend.png" alt="Logo 3" class="card-logo">
                        </div>
                        <div class="card-footer">
                            <p class="card-text">Vs Last Month</p>
                        </div>
                    </div>
                </div>
            </div>
        
            <div class="actions">
                <div class="">
                    <button  (click)="scheduleMeetingDialog=true" class="btn">Schedule a meeting</button>
                    <button (click)="visible=true"  class="btn">Add Jotter to live meeting</button>
                </div>
            
    
                <div class="item">
                    <button class="btn">Upload audio or transcript</button>
                    <button class="btn">Record meeting</button>
                </div>
              
            </div>
            <div class="meetings">
                <h2>Upcoming meetings</h2>
                <div class="calendar-week">
                    <div class="calendar-day"  *ngFor="let day of weekDays; let i = index" [class.selected]="i === selectedDayIndex" (click)="selectDay(i)">
                      <div class="day-name">{{ day.name }}</div>
                      <div class="day-date">{{ day.date }}</div>
                    </div>
                    <!-- <div class="calendar"  *ngFor="let day of weekDays; ">
                        <div class="day">Mo<br><br>29</div> -->
                        <!-- <div class="day">Tu<br><br>30</div>
                        <div class="day active">We<br><br>31</div>
                        <div class="day">Th<br><br>1</div>
                        <div class="day">Fr<br><br>2</div>
                        <div class="day">Sa<br><br>3</div>
                        <div class="day">Su<br><br>4</div> -->
                    <!-- </div> -->
                  </div>
                <div class="meeting-cards">
                    <div class="meeting-card" *ngFor="let meeting of upcomingEvents">
                        <h3>{{meeting?.title}}</h3>
                        <p><i class="far fa-clock"></i> {{meeting.time}}</p>
                        <div class="loction-copy">
                            <p><i class="pi pi-map-marker"></i> {{meeting.location}}</p>
                            <div class="copy-link">
                            <p><i class="pi pi-copy"></i> </p>
                                
                            </div>
                        </div>
                        <div>
                            <button class="join-btn" (click)="onClickScheduleButton(meeting.link)">Join</button>

                        </div>
                    </div>
                    <!-- <p-progressSpinner aria-label="Loading"></p-progressSpinner> -->

                    <div  *ngIf="upcomingEvents.length === 0"class="meeting-cards">No Upcoming meeting found</div>
                </div>
            </div>
        </div>
    
    </div>

    <p-dialog [(visible)]="scheduleMeetingDialog" [breakpoints]="{ '960px': '75vw' }" [draggable]="false" [modal]="true"
    [style]="{width: '40vw'}">
    <p-header class="dialogHeader">
            <h3 class="header-dialog-new-meeting"><b>Schedule Meeting via </b></h3>
        <!-- <p-divider></p-divider> -->
    </p-header>

    <div class="relative">
        <div class="p-field">
            <div *ngFor="let option of scheduleMeetingOption">
                <div class="new-schedule-card flex" (click)="onClickScheduleButton(option.link)">
                    <img src="{{option.icon}}" width="45px" height="45px" />
                    <p>{{option.title}}</p>
                </div>
            </div>
        </div>
    </div>
</p-dialog>


<p-dialog [(visible)]="visible" [breakpoints]="{ '960px': '75vw' }" [draggable]="false" [modal]="true"
          [style]="{width: '30vw'}" header="Capture live meeting">
    <div class="relative">
        <div class="p-field">
            <label class="block text-900  font-medium my-2" for="name">Name your meeting</label>
            <input style="width:100%" [(ngModel)]="meetingData.name" autofocus
                   id="name"
                   name="name"
                   pInputText
                   required
                   type="text"/>
        </div>
        <div class="p-field">
            <label class="block text-900 font-medium mt-2" for="link">Meeting link</label>
            <span class="info-lable">Capture meetings from GMeet, Zoom, MS teams, and more.            </span>
            <input style="width:100%" [(ngModel)]="meetingData.link" autofocus
                   id="link"
                   ngModel
                   pInputText
                   required
                   type="text"/>
        </div>
        <div class="p-field">
            <label class="block text-900 font-medium my-2" for="link">Attendees</label>
            <p-chips placeholder="Enter name or email"  (onAdd)=checkAttendeesEmail($event) required [(ngModel)]="meetingData.attendees"></p-chips>

        </div>
        <div class="p-field">
            <label class="block text-900 font-medium my-2" for="link">Date & Time</label>
            <p-calendar [(ngModel)]="meetingData.createdAt" [showTime]="true"></p-calendar>
        </div>

        <div class="p-field">

            <div class="d-flex justify-content-end ">
                <button (click)="saveMeeting()" class="p-button-secondary left mt-2" label="Save"
                        pButton
                        pRipple></button>
            
            </div>
        </div>
    </div>
</p-dialog>
