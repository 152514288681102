<div class="layout-wrapper" [ngClass]="{
                                'layout-static':true,
                                'layout-theme-light':!config.dark,
                                'layout-theme-dark':config.dark,
                                'layout-overlay-sidebar-active': overlayMenuActive,
                                'layout-static-sidebar-inactive': staticMenuInactive,
                                'layout-mobile-sidebar-active':menuActiveMobile,
                                'p-ripple-disabled': !config.ripple,
                                'p-input-filled': config.inputStyle === 'filled'
                                }">
    <div class="layout-sidebar">
        <a class="logo" routerLink="">
            <img src="assets/images/logo.png" alt="CONN">
        </a>
        <app-menu></app-menu>
    </div>
    <div class="layout-main-container">
        <app-topbar></app-topbar>
        <div class="layout-main">
            <app-loader [displayLoader]="loaderService.displayLoader"></app-loader>
            <router-outlet></router-outlet>
        </div>
    </div>
    <div *ngIf="menuActiveMobile" class="layout-mask p-component-overlay"></div>
</div>
