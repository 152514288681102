import {Injectable} from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {LoginService} from '../service/login.service';
import {tap} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    user: any;

    constructor(private loginService: LoginService) {
    }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (this.loginService.getToken() !== null) {
            request = request.clone({
                setHeaders: {
                    authorization: `${this.loginService.getToken()}`,
                },
            });
        } else {
            this.user = this.loginService.getUser();
            if (this.user) {
                request = request.clone({
                    setHeaders: {
                        authorization: `${this.user.token}`,
                        uid: `${this.user.uId}`,
                    },
                });
            }
        }
        return next.handle(request).pipe(
            tap(
                () => {
                },
                (err: any) => {
                    if (err instanceof HttpErrorResponse) {
                        if (err.status === 401) {
                            this.loginService.googleSignOut();
                        }
                    }
                }
            )
        );
    }
}
