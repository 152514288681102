<div class="grid">
    <div class="col-12">
        <app-loader [displayLoader]="showLoader"></app-loader>

        <full-calendar
            [events]="meetings"
            [options]="calendarOptions"></full-calendar>

    </div>

</div>

<p-dialog header="Meeting" [breakpoints]="{ '960px': '75vw' }"[(visible)]="visible" [style]="{width: '30vw'}">
    <div class="relative">
        <div class="p-field">
            <label for="name" class="block text-900  font-medium my-2">Name</label>
            <input id="name" type="text"
                   pInputText
                   autofocus
                   name="name"
                   required
                   [(ngModel)]="meetingData.name"/>
        </div>
        <div class="p-field">
            <label for="link" class="block text-900 font-medium my-2">Meet Link</label>
            <input id="link" type="text"
                   pInputText
                   autofocus
                   [(ngModel)]="meetingData.link"
                   required
                   ngModel/>
        </div>
        <div class="p-field"*ngIf="isEdit">
            <label for="link" class="block text-900 font-medium my-2">Status</label>
            <div>
                            <span *ngIf="meetingData.status == 'in_Progress'"
                                  [class]="'product-badge status-outofstock'">in Progress</span>
                <span *ngIf="meetingData.status == 'completed'"
                      [class]="'product-badge status-instock'">Completed</span>
                <span *ngIf="meetingData.status == 'not_Started'"
                      [class]="'product-badge status-lowstock'">Not Started</span>
            </div>
        </div>
        <div class="p-field"*ngIf="!isEdit" #moms>
            <label for="link" class="block text-900 font-medium my-2">Meeting of Minutes</label>
            <textarea pInputTextarea rows="5" cols="50" class="block text-900 font-medium my-2">{{meetingData.mom}}</textarea>
            <div (click)="onClickDownloadPdf()">
                <i class="fa fa-download"></i>
            </div>
        </div>
        <div class="right"*ngIf="!isEdit">
            <button pButton pRipple label="Save"
                    (click)="saveMeeting()"
                    class="p-button-success right mt-2"></button>
        </div>
        <div class="right"*ngIf="isEdit">
            <button pButton pRipple label="Close"
                    (click)="closeDialog()"
                    class="p-button-danger right mt-2"></button>
        </div>
        </div>
</p-dialog>


