import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../../service/app.config.service';
import { LoginService } from 'src/app/service/login.service';
import { Router } from '@angular/router';
import { map } from 'rxjs';
import { MessageService } from 'primeng/api';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-singup',
    templateUrl: './singup.component.html',
    styleUrls: ['./singup.component.scss'],
})
export class SignupComponent implements OnInit {
    user: any;
    password: any = 'password';
    show: boolean = false;
    submitted = false;


    signupForm: FormGroup;
    formFields = [
      { controlName: 'firstName', placeholder: 'First name', icon: 'pi pi-user', type: 'text', errorMessage: 'First name is required' },
      { controlName: 'lastName', placeholder: 'Last name', icon: 'pi pi-user', type: 'text', errorMessage: 'Last name is required' },
      { controlName: 'mobileNumber', placeholder: 'Phone number', icon: 'pi pi-phone', type: 'text', errorMessage: 'Phone number is required' },
      { controlName: 'email', placeholder: 'Email Address', icon: 'pi pi-envelope', type: 'email', errorMessage: 'Email is required' },
      { controlName: 'password', placeholder: 'Password', icon: 'pi pi-lock', type: 'password', errorMessage: 'Password is required' },
      { controlName: 'confirmPassword', placeholder: 'Confirm Password', icon: 'pi pi-lock', type: 'password', errorMessage: 'Confirm password is required' }
    ];
    // signupForm: FormGroup = new FormGroup({
    //     'firstName': new FormControl('', Validators.required),
    //     'lastName': new FormControl('', Validators.required),
    //     'email': new FormControl('', [Validators.required, Validators.email]),

    //     'password': new FormControl('', Validators.required),
    //     'confirmPassword': new FormControl('', Validators.required),
    //     'mobileNumber': new FormControl('', [Validators.required])
    // }, {
    //     validators: [this.passwordMatchValidator]
    // });

    response: any;

    constructor(
        public configService: ConfigService,
        private loginService: LoginService,
        private router: Router,
        private messageService: MessageService,
        private fb: FormBuilder
    ) {
    }

    ngOnInit(): void {
        this.signupForm = this.fb.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            mobileNumber: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required],
            confirmPassword: ['', Validators.required]
          });
        if (this.loginService.getToken()) {
            this.router.navigate(['/']);
        }
    }
    get f(): { [key: string]: AbstractControl } {
        return this.signupForm.controls;
    }

    async signIn() {
        try {
            const userData = await this.loginService
                .googleSignIn()
                .pipe(
                    map(
                        (res) => (
                            (this.user = res.user),
                            {
                                token: this.user.accessToken,
                                uId: this.user.uid,
                                name: this.user.displayName,
                                email: this.user.email,
                                phoneNo: this.user.mobileNumber,
                                profileImg: this.user.photoURL,
                            }
                        )
                    )
                )
                .toPromise();
            this.response = await this.loginService.sendData(userData);
            this.loginService.setSignInDetails(this.response.data);
            this.loginService.setToken(this.response.data);
            this.router.navigate(['/']);
        } catch (error) {
            console.error(error);
            this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Server Error.',
            });
        }
    }
    // Custom validator function for password match
    private passwordMatchValidator(control: AbstractControl) {
        const password = control.get('password').value;
        const confirmPassword = control.get('confirmPassword').value;
        if (password !== confirmPassword) {
            control.get('confirmPassword').setErrors({ passwordMismatch: true });
        } else {
            control.get('confirmPassword').setErrors(null);
        }

        return null;
    }
    signOut() {
        this.loginService.googleSignOut();
    }

    async onSubmit() {
        this.submitted = true;
        if (this.signupForm.valid) {
            try {
                this.response = await this.loginService.Register(this.signupForm.value)
                this.router.navigate(['/otp'], {
                    state: {

                        "email": this.signupForm.value.email,
                    }
                });
                this.submitted=false
            } catch (error) {
                this.submitted=false
                console.error("ERROR1111", error)
                const message=error?.error?.description?error?.error?.description:( error?.error?.data[0] ? error?.error?.data[0].msg : "Server Error")
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail:message
                });
            }
        } else {
            this.submitted = false
            this.markFormGroupTouched(this.signupForm);

        }
    }
    onClick() {
        if (this.password === 'password') {
            this.password = 'text';
            this.show = true;
        } else {
            this.password = 'password';
            this.show = false;
        }
    }
    // Helper function to mark all controls in a form group as touched
    private markFormGroupTouched(formGroup: FormGroup) {
        Object.values(formGroup.controls).forEach(control => {
            control.markAsTouched();

            if (control instanceof FormGroup) {
                this.markFormGroupTouched(control);
            }
        });
    }
}
