<div class="grid">
    <app-loader [displayLoader]="showLoader"></app-loader>

    <div class="col-12 lg:col-16 xl:col-4">
    <p-card  header="JIRA" subheader="Get action items from meetings to your JIRA board" [style]="{width: '360px'}" styleClass="shadow-2">
        <ng-template pTemplate="header">
            <img alt="Card" src="assets/images/jira_software_logo.png">
        </ng-template>
        <div class="d-flex justify-content-between">
            <p-inputSwitch (onChange)="eventHandler($event)" [(ngModel)]="isJiraEnable"></p-inputSwitch>
            <a *ngIf="isJiraEnable" (click)="visible=true" class="card-integration text-blue-500 text-xl">Edit</a>
        </div>
        
        

    </p-card>
    </div>
    <div class="col-12 lg:col-16 xl:col-4" >
        <p-card  header="ASANA" subheader="Get action items from meetings to your asana board" [style]="{width: '360px'}" styleClass="shadow-2">
            <ng-template pTemplate="header">
                <img style="width: 78%" alt="Card" src="assets/images/asana_logo.png">
            </ng-template>
            <div class="d-flex justify-content-between">
                <p-inputSwitch [disabled]="true"></p-inputSwitch>
                <a class="text-500 text-xl">Coming soon</a>
            </div>
        </p-card>
    </div>
</div>
<p-dialog header="Integration" [breakpoints]="{ '960px': '75vw' }"[(visible)]="visible" [style]="{width: '30vw'}">
    <div class="relative">
        <div class="p-field">
            <label for="name" class="block text-900  font-medium my-2">JIRA USERNAME</label>
            <input id="name" type="text"
                   pInputText
                   autofocus
                   required
                   [(ngModel)]="integrationData.JIRA_USERNAME"/>
        </div>
        <div class="p-field">
            <label for="link" class="block text-900 font-medium my-2">JIRA API TOKEN</label>
            <input id="link" type="text"
                   pInputText
                   autofocus
                   [(ngModel)]="integrationData.JIRA_API_TOKEN"
                   required
                   ngModel/>
        </div>
        <div class="p-field">
            <label for="link" class="block text-900 font-medium my-2">PROJECT KEY</label>
            <input id="link" type="text"
                   pInputText
                   autofocus
                   [(ngModel)]="integrationData.PROJECT_KEY"
                   required
                   ngModel/>
        </div>
        <div class="p-field">
            <label for="link" class="block text-900 font-medium my-2">JIRA BASE URL</label>
            <input id="link" type="text"
                   pInputText
                   autofocus
                   [(ngModel)]="integrationData.JIRA_BASE_URL"
                   required
                   ngModel/>
        </div>
        <!-- <div class="p-field"*ngIf="isEdit">
            <label for="link" class="block text-900 font-medium my-2">Status</label>
            <div>
                            <span *ngIf="meetingData.status == 'in_Progress'"
                                  [class]="'product-badge status-outofstock'">in Progress</span>
                <span *ngIf="meetingData.status == 'completed'"
                      [class]="'product-badge status-instock'">Completed</span>
                <span *ngIf="meetingData.status == 'not_Started'"
                      [class]="'product-badge status-lowstock'">Not Started</span>
            </div>
        </div> -->
        <div class="p-field"*ngIf="isEdit">
            <label for="link" class="block text-900 font-medium my-2">Meeting of Minutes</label>
            <textarea pInputTextarea rows="5" cols="50" class="block text-900 font-medium my-2">{{meetingData.mom}}</textarea>
        </div>
        <div class="right"*ngIf="!isEdit">
            <button pButton pRipple label="Save"
                    (click)="saveintegrationData()"
                    class="p-button-success right mt-2"></button>
        </div>
        <div class="right"*ngIf="isEdit">
            <button pButton pRipple label="Close"
                    (click)="closeDialog()"
                    class="p-button-danger right mt-2"></button>
        </div>
        </div>
</p-dialog>
