import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../../service/app.config.service';
import { LoginService } from 'src/app/service/login.service';
import { Router } from '@angular/router';
import { map } from 'rxjs';
import { MessageService } from 'primeng/api';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LoaderService } from 'src/app/service/loader.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
    user: any;
    password: any = 'password';
    show: boolean = false

    loginForm: FormGroup = new FormGroup({
        'email': new FormControl('', Validators.required),
        'password': new FormControl('', Validators.required)
    });
    isLoader=false;
    response: any;

    constructor(
        public configService: ConfigService,
        private loginService: LoginService,
        private router: Router,
        private messageService: MessageService,
        private loaderService: LoaderService
    ) {
    }

    ngOnInit(): void {
        if (this.loginService.getToken()) {
            this.router.navigate(['/']);
        }
    }

    async signIn() {
        try {
            const userData = await this.loginService
                .googleSignIn()
                .pipe(
                    map(
                        (res) => (
                            (this.user = res.user),
                            {
                                token: this.user.accessToken,
                                uId: this.user.uid,
                                name: this.user.displayName,
                                email: this.user.email,
                                phoneNo: this.user.phoneNumber,
                                profileImg: this.user.photoURL,
                            }
                        )
                    )
                )
                .toPromise();
            this.response = await this.loginService.sendData(userData);
            this.loginService.setSignInDetails(this.response.data);
            this.loginService.setToken(this.response.data);
            this.router.navigate(['/']);
        } catch (error) {
            console.error(error);
            this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Server Error.',
            });
        }
    }

    signOut() {
        this.loginService.googleSignOut();
    }

    async submit() {
        if (this.loginForm.valid) {
            this.isLoader=true;
            try {
                this.loaderService.loader(true);
                this.response = await this.loginService.loginWithEmailPassowrd(this.loginForm.value)
                this.loginService.setSignInDetails(this.response.data);
                this.loginService.setToken(this.response.data);
                this.loaderService.loader(false);
                this.isLoader=false;
                this.router.navigate(['/']);
            } catch (error) {
                this.isLoader=false;
                console.error("ERROR1111", error.error)
                if (error?.error?.data && error.error.data.isUserVerified == false) {
                    this.resetOtp(error.error.data.email)
                    return;
                }
                const message=error?.error?.message?error?.error?.message:( error?.error?.data[0] ? error?.error?.data[0].msg : "Server Error")
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail:message
                });
                this.loaderService.loader(false);
            }
        }
    }
    onClick() {
        if (this.password === 'password') {
            this.password = 'text';
            this.show = true;
        } else {
            this.password = 'password';
            this.show = false;
        }
    }
    async resetOtp(email) {
        try {
            this.isLoader=true;

            this.response = await this.loginService.resendOtp({ email,type:this.loginService.OTP_TYPE.FORGETPASSOTP, })
            this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: this.response.message
            });
            this.router.navigate(['/otp'], {
                state: {

                    "email": email,
                }
            });
            this.isLoader=false;
            this.loaderService.loader(false);
        } catch (error) {
            this.isLoader=false;
            const message=error?.error?.message?error?.error?.message:( error?.error?.data[0] ? error?.error?.data[0].msg : "Server Error")
            this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail:message
            });
            this.loaderService.loader(false);
        }
    }
}
