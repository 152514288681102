<div class="files-cover">
    <div class="files-list">
        <div *ngFor="let file of allFiles;" class="uploaded-file" (click)="selectFolderFile(file)" >
            <div *ngIf="isFile" >
                <img *ngIf="filesExtension.docs.includes(file.s3Key?.split('.').pop().toLowerCase() )"
                src="/assets/images/pdf-icon.svg" height="24" width="24" alt="Icon" />
            <img *ngIf="filesExtension.pdf.includes(file.s3Key?.split('.').pop().toLowerCase() )"
                src="/assets/images/pdf.svg" height="24" width="24" alt="Icon" />
            <img *ngIf="filesExtension.csv.includes(file.s3Key?.split('.').pop().toLowerCase() )"
                src="/assets/images/csv.png" height="24" width="24" alt="Icon" />
            </div>
           
            <div *ngIf="!isFile"  >
                <img
                src="/assets/images/fi_folder.png" height="24" width="24" alt="Icon" />
            </div>
            <p class=file-name>{{ file.fileName?file.fileName:file.name }}</p>
        </div>
    </div>
</div>