import {Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ApiService} from "../../service/api.service";
import {Urls} from "../../utils/urls";
import {CONNECTION_TYPE} from "../../utils/consts"
import {MessageService} from "primeng/api";
import {UtilsService} from "../../service/utils.service";
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-connection',
    templateUrl: './connection.component.html',
    styleUrls: ['./connection.component.scss']
})
export class ConnectionComponent implements OnInit {
    @Input() name: string;
    @Input() link: string;
    @Input() meetingData: any;
    @Input() mom: any;
    @Input() action: string;
    @Output() closeSidebar = new EventEmitter<boolean>();
    editId;
    showLoader = false;
    form: FormGroup;
    selectedFile: File;
    editConnection
    statusOptions = [
        {label: 'Enable', value: 'enable'},
        {label: 'Disable', value: 'disable'},
    ];
    connectionTypes = [
        CONNECTION_TYPE.GHL,
        CONNECTION_TYPE.MANY_CHAT,
    ]
    ghlFormGroup: FormGroup = new FormGroup({
        locationId: new FormControl(null, {
            validators: [Validators.required]
        }),
        apiKey: new FormControl(null, {
            validators: [Validators.required]
        }),
    })

    constructor(private readonly apiService: ApiService, private messageService: MessageService,
        private utilsService: UtilsService) {}

    @ViewChild('export', { static: false }) pdf: ElementRef;
    @ViewChild('moms', { static: false }) moms: ElementRef;
    @ViewChild('actionItems', { static: false }) actionItems: ElementRef;

    ngOnInit() {
        const formFields = {
            name: new FormControl(null, {
                validators: [Validators.required]
            }),
            link: new FormControl(null, {
                validators: [Validators.required]
            }),
            timeout: new FormControl(null, {
                // validators: [Validators.]
            }),
            meetingEndTime: new FormControl(null, {
                // validators: [Validators.required]
            }),
            // type: new FormControl(CONNECTION_TYPE.GHL, {
            //     validators: [Validators.required]
            // }),
            // ghl: this.ghlFormGroup
        }
        if (this.editId) {
            this.fetchConnection(this.editId)
            this.form = new FormGroup({
                status: new FormControl(null, {
                    validators: [Validators.required]
                }),
                ...formFields
            });
        } else {
            this.form = new FormGroup(formFields);
        }
    }

    onSelected(event: any) {
        this.selectedFile = event.files[0];
    }

    async saveConnection() {
        this.showLoader = true;
        return await this.saveOrUpdateConnection(this.editConnection);
        if (this.editConnection) {

        } else {
            const fileReader = new FileReader();
            fileReader.readAsText(this.selectedFile, 'UTF-8');
            fileReader.onload = async () => {
                if (fileReader.result && typeof fileReader.result === 'string') {
                    await this.saveOrUpdateConnection(JSON.parse(fileReader.result));
                }
            }
        }
    }

    async saveOrUpdateConnection(dialogFlowCred) {
        try {
            this.showLoader = true;
            if (this.editId) {
                await this.apiService.put(`${Urls.meeting}/${this.editId}`, {...this.form.value})
            } else {
                await this.apiService.post(Urls.meeting, {...this.form.value})
            }
            this.showLoader = false;
            this.closeSidebar.emit(true);
            this.messageService.add({
                severity: 'success',
                summary: 'Successful',
                detail: this.editId ? 'Connection Updated' : 'Connection Created.',
                life: 3000,
            });
        } catch
            (error) {
            this.showLoader = false;
            console.error(error);
            this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: this.editId ? 'Unable to update connection.' : 'Unable to create connection.',
                life: 3000,
            });
        }
    }

    async fetchConnection(editId) {
        try {
            this.showLoader = true;
            const response: any = await this.apiService.get(`${Urls.connection}/${editId}`)
            this.editConnection = response.data
            if (this.editConnection.type === CONNECTION_TYPE.GHL) {
                this.form.addControl('ghl', this.ghlFormGroup);
            } else {
                this.form.removeControl('ghl')
            }
            this.form.patchValue(this.editConnection);
            this.showLoader = false;
        } catch (e) {
            this.showLoader = false;
            console.error(e);
        }
    }

    changedType(event: any) {
        if (event.value && event.value === CONNECTION_TYPE.GHL) {
            this.form.addControl('ghl', this.ghlFormGroup);
        } else {
            this.form.removeControl('ghl')
        }
    }
     isJsonString(str) {
        return typeof str === 'string'
    }

    onClickDownloadPdf() {
        var link = document.createElement("a");
        // link.download = name;
        link.href = `${environment.apiUrl}/document/${this.meetingData.docLink}`;
        link.target = '_blank';
        link.click();

    //    if (this.mom != undefined && this.action != undefined && this.mom.length > 0 && this.action.length > 0) {
    //         //this.utilsService.downloadPDF({"Testing": "Testing"}, this.name);
    //         this.utilsService.downloadAsPDF(document.getElementById('export'), this.name);
    //    }
    }
}
