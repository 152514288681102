import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { log } from 'console';
import { ApiService } from 'src/app/service/api.service';
import { LoaderService } from 'src/app/service/loader.service';
import { UtilsService } from 'src/app/service/utils.service';
import { Urls } from 'src/app/utils/urls';

@Component({
  selector: 'app-licence',
  templateUrl: './licence.component.html',
  styleUrls: ['./licence.component.scss']
})
export class LicenceComponent implements OnInit {
  allLicence:any = [];
visible: boolean = false;
yearlyDetail:any = {
  yearlyData:[]
};
user=[]
  totalDocs =0
  constructor(private readonly apiService: ApiService, private loaderService: LoaderService, readonly utilsService: UtilsService) {
  }


  ngOnInit(): void {
    // this.getUsers();
  }
  async getUsers() {
    const params = {
    }
    this.loaderService.loader(true);
    const res: any = await this.apiService.get(Urls.licenceAll, {params: params as unknown as HttpParams})
    this.loaderService.loader(false);
    this.allLicence = res.data;
    this.totalDocs=res.data.length;
}

showDialog(user) {
    this.visible = true;
    this.yearlyDetail = user
    this.yearlyDetail.yearlyData[0].monthlyData.push({
      totalHours:600,
      totalUsedHours:100,
      month:"JAN"
    })
}
getTotalGrandTotal(): number {
  // Calculate and return the grand total from your data
  let grandTotal = 0;
  for (const user of this?.yearlyDetail?.yearlyData) {
          grandTotal += user.usedHours; // Adjust based on your data structure

    // for (const yearlyData of user.yearlyData) {
    // }
  }
  return grandTotal;
}
}
